import React, { ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'next/link';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useRouter } from 'next/router';
import { makeStyles } from '@material-ui/core/styles';

import { IWebsiteMenu, IWebsiteMenuType, WebsiteLogoPosition } from '@/shared/models/Website';
import { useClub } from 'contexts/club';
import { Colors, ITheme } from '@/shared/styles/MuiTheme';
import ClubLogo from '../WebsiteHeader/Elements/ClubLogo';
import MButton from '@/shared/components/Button/MButton';
import { useAuth } from 'contexts/auth';
import BackButton from '@/shared/components/Button/BackButton';
import { Paragraph1 } from '@/shared/components/Typography';
import { isEmpty, isUndefined } from '@/shared/utils/lodashFunc';
import { getActiveMenuTree, getCurrentMenuItem, getHomePage, getMenuUrl, isHomePage } from './utils';
import { FLF_SHOP_ICON, FLF_SHOP_LINK, FLF_TICKETS_LINK, FLF_TICKET_ICON } from '@/shared/constants';
import { sectionMessages } from 'components/Sections/messages';
import joinElements from '@/shared/utils/joinElements';
import { CLUBEE_BLACK } from '@/shared/constants'
import FlfNavLinkButton from '../WebsiteHeader/Elements/FlfNavLinkButton';
import SecondaryButton from '@/shared/components/Button/SecondaryButton';
import PrimaryButton from '@/shared/components/Button/PrimaryButton';
import { getOriginFromUrl } from 'utils/clubs';

interface IStypesProps {
    isUserLoggedIn: boolean;
    isFlf: boolean,
    bottomHeight: string
}

const useStyles = makeStyles((theme: ITheme) => {
    const topHeight = '64px'
    const paddingBottom = theme.spacing(5)
    const gap = theme.spacing(3)
    const menuHeight = ({ isUserLoggedIn, bottomHeight }: IStypesProps) => isUserLoggedIn ? `calc(100vh - (${topHeight} + ${gap}px + ${paddingBottom}px + ${bottomHeight}))` : `calc(100vh - (${topHeight} + ${gap}px + ${bottomHeight} + ${gap}px + ${paddingBottom}px))`
    const background = (props: IStypesProps) => props.isFlf ? '#FFFFFF' : theme.props.sectionMode.background
    return ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            paddingBottom: paddingBottom,
            gap: gap,
            background,
            color: (props: IStypesProps) => props.isFlf ? CLUBEE_BLACK : theme.props.sectionMode.text_background,
            '& a': {
                textDecoration: 'none'
            },
        },
        top: {
            height: topHeight,
            display: 'flex',
            padding: theme.spacing(0, 4),
            alignItems: 'center'
        },
        center: {
            flex: 1,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(3)
        },
        closeBtn: {},
        logo: {
            height: '40px',
            width: '40px',
            '& img': {
                maxHeight: '40px',
                maxWidth: '40px',
            },
        },
        leftLogo: {
            justifyContent: 'space-between'
        },
        middleLogo: {
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
            '& $logo': {
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
            },
        },
        bigLeftLogo: {
            justifyContent: 'space-between',
            '& $logo': {
                height: '48px',
                width: '48px',
                '& img': {
                    maxHeight: '48px',
                    maxWidth: '48px',
                },
            }
        },
        bottom: {
            height:(props: IStypesProps) => props.bottomHeight,
            padding: theme.spacing(0, 4),
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center'
        },
        inner: {
            overflow: 'hidden'
        },
        backButton: {
        },
        menu: {
            fontSize: '20px', //NOTE size for svg icons
            position: 'relative',
            overflow: 'unset',
            height: menuHeight,
            minHeight: '320px',
            '& p': {
                color: (props: IStypesProps) => props.isFlf ? `${CLUBEE_BLACK} !important` : undefined
            }
        },
        menuSelected: {
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        menuHasSelected: {
            overflow: 'unset',
        },
        menuItem: {
            margin: theme.spacing(0, 4),
            height: '56px',
            display: 'flex',
            alignItems: 'center',
            borderBottom: (props:IStypesProps) => `1px solid ${props.isFlf ? Colors.FlfDividerColor : theme.props.sectionMode.card}`,
            position: 'static',
        },
        menuItemActive: {
            '& $menuTitle': {
                borderLeft: `4px solid ${theme.props.sectionMode.accent}`,
            }
        },
        flfMenuItemActive: {
            '& $menuTitle': {
                borderLeft: `4px solid ${Colors.FlfRedColor}`,
            }
        },
        subMenu: {
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            transform: 'translateX(100vw)',
            transition: 'transform 0.4s ease',
            overflow: 'hidden',
            height: '100%',
            background,
        },
        subMenuSelected: {
            transform: 'translateX(0)!important',
            overflowY: 'auto',
        }, 
        subMenuHasSelected: {
            transform: 'translateX(0)!important',
            overflow: 'unset',
        },   
        menuTitle: {
            flex: 1,
            display: 'flex',
            height: '32px',
            alignItems: 'center',
            paddingLeft: theme.spacing(2),
            '& a': {
                flex: 1
            }
        },
        menuArrow: {},
        cursor: {
            cursor: 'pointer'
        }
    })
}) 

interface IProps {
    handleMobileMenuClose: () => void;
    isOpen: boolean;
    isPreview?: boolean
    previewMenus?: IWebsiteMenu[]
}

export default function WebsiteMobileMenu({ handleMobileMenuClose, isOpen, isPreview, previewMenus }: IProps): JSX.Element {
    const { website, menu: currentMenus, pathPrefix, isFlf } = useClub()
    const bottomHeight = '50px'
    const menu = previewMenus || currentMenus
    const { token } = useAuth()
    const router = useRouter()
    const intl = useIntl()
    const classes = useStyles({ isUserLoggedIn: !!token, isFlf, bottomHeight })
    const anchor = website.logo_position === WebsiteLogoPosition.MIDDLE ? 'left' : 'right'

    const headerClass = website.logo_position === WebsiteLogoPosition.LEFT ?
        classes.leftLogo :
            website.logo_position === WebsiteLogoPosition.MIDDLE ?
            classes.middleLogo : classes.bigLeftLogo
    
    const pageSlug = isUndefined(router.query.slug) || isEmpty(router.query.slug) ? null : router.query.slug[0]
    const activeMenuItem = pageSlug ? getCurrentMenuItem(menu, pageSlug) : isHomePage(router) ? getHomePage(menu) : null //active menu of the current page
    const activeMenuTree = activeMenuItem ? getActiveMenuTree(menu, activeMenuItem.id, []) : [] //tree of all active parents/children menu of the current page
    const [ selectedMenu, setSelectedMenu ] = useState<IWebsiteMenu | null>(null)
    const [ selectedMenuTree, setSelectedMenuTree ] = useState<number[]>([])

    function selectMenu(item: IWebsiteMenu) {
        if (!isUndefined(item) && item) {
            setSelectedMenu(item)
            setSelectedMenuTree(getActiveMenuTree(menu, item.id, []))
        } else {
            setSelectedMenu(null)
            setSelectedMenuTree([])
        }
    }
    
    function renderButtons(): JSX.Element {
        let loginButton: ReactNode | null = null
        const origin = getOriginFromUrl(pathPrefix, router.asPath) 
        if (isFlf) {
            loginButton = <PrimaryButton key={3} size='small' isClubExternal={false} href={process.env.NEXT_PUBLIC_FLF_EXTRANET_LINK}>
                            <FormattedMessage id='v4_website.extranet' defaultMessage='Log in' />
                        </PrimaryButton>
        }
        if(!token && !isFlf) {
            loginButton = <SecondaryButton key={3} isClubExternal href={`/user/login?origin=${origin}`} >
                            <FormattedMessage id='v4_website.btn.login' defaultMessage='Log in' />
                        </SecondaryButton>
        }
        return <div className={classes.bottom}>
            {joinElements([
                isFlf && <FlfNavLinkButton startImg imgSrc={FLF_SHOP_ICON} openInNewWindow href={FLF_SHOP_LINK} key={1}>
                    {intl.formatMessage(sectionMessages['navigation.btn.shop'])}
                </FlfNavLinkButton>,
                isFlf && <FlfNavLinkButton startImg imgSrc={FLF_TICKET_ICON} openInNewWindow href={FLF_TICKETS_LINK} key={2}>
                    {intl.formatMessage(sectionMessages['navigation.btn.tickets'])}
                </FlfNavLinkButton>,
                loginButton,
            ], <div style={{ marginLeft: 8 }} />)}
        </div>
    }

    function renderLinkMenuItem(item: IWebsiteMenu, isTitle = false): JSX.Element {
        const href =  getMenuUrl(item, pathPrefix, isPreview)
        const element = (mItem: IWebsiteMenu) => isTitle ? <Paragraph1><b>{mItem.name}</b></Paragraph1> : <Paragraph1>{mItem.name}</Paragraph1>

        if (href) {
            return (
                item.type === IWebsiteMenuType.LINK || item.url === 'va/shop_overview' ?
                    <a href={href} {...item.blank ? {target: '_blank', rel: 'noreferrer'} : {}} onClick={handleMobileMenuClose}>
                            {element(item)}
                    </a> :
                    <Link href={href} passHref>
                        <a {...item.blank ? {target: '_blank', rel: 'noreferrer'} : {}} onClick={handleMobileMenuClose}>
                            {element(item)}
                        </a>
                    </Link>
            )
        }
        return element(item)
    }

    function renderMenuItem(item: IWebsiteMenu, parent?: IWebsiteMenu ): JSX.Element {
        const isActiveMenu = !isEmpty(activeMenuTree) && activeMenuTree.includes(item.id)
        const hasSelectedMenu = !isEmpty(selectedMenuTree) && item.id !== selectedMenu?.id && selectedMenuTree.includes(item.id)
        const isSelectedMenu = !isEmpty(selectedMenu) && item.id === selectedMenu?.id 

        return (
            <div key={item.id} className={`${classes.menuItem}${isActiveMenu ? ` ${isFlf ? classes.flfMenuItemActive : classes.menuItemActive}` : ''}`}>
                {!isEmpty(item.children) ? (
                    <>
                        <div onClick={() => selectMenu(item)} className={`${classes.menuTitle} ${classes.cursor}`}><Paragraph1>{item.name}</Paragraph1></div>
                        <div onClick={() => selectMenu(item)} className={`${classes.menuArrow} ${classes.cursor}`}><ArrowForwardIosIcon /></div>
                        <div className={`${classes.subMenu}${isSelectedMenu ? ` ${classes.subMenuSelected}` : hasSelectedMenu ? ` ${classes.subMenuHasSelected}` : ''}`}>
                            <div className={classes.inner}>
                                <div className={classes.backButton}>
                                    <BackButton
                                        size='small'
                                        isClubExternal
                                        onClick={() => selectMenu(parent)}
                                    />
                                </div>
                                <div className={classes.menuItem}>
                                    <div className={classes.menuTitle}>{renderLinkMenuItem(item, true)}</div>
                                </div>
                                {
                                    item.children.map((subItem) => {
                                        return renderMenuItem(subItem, item)
                                    })
                                }
                            </div>
                        </div>
                    </>
                ) : <div className={classes.menuTitle}>{renderLinkMenuItem(item)}</div>}
            </div>
        )
    }
    
    function renderMenu(menu: IWebsiteMenu[]): JSX.Element {
        const isSelected = !selectedMenu || menu.find(item => isEmpty(item.children) && item.id === selectedMenu?.id)
        return (
            <div className={`${classes.menu}${isSelected ? ` ${classes.menuSelected}` : ` ${classes.menuHasSelected}`}`}> 
                <nav className={classes.inner}>
                    {menu.map((menuItem) => {
                        return renderMenuItem(menuItem)
                    })}
                </nav>
            </div>
        )
    }

    return (
        <Drawer
            anchor={anchor}
            open={isOpen}
            onClose={handleMobileMenuClose}
        >
            <div className={classes.root}>
                <div className={`${classes.top} ${headerClass}`}>
                    <div className={classes.logo}><ClubLogo /></div>
                    <div className={classes.closeBtn}>
                        <MButton onClick={handleMobileMenuClose} iconSize={48} isIcon color="inherit" aria-label="menu">
                            <CloseIcon />
                        </MButton>
                    </div>
                </div>
                <div className={classes.center}>                    
                    {renderMenu(menu)}
                </div>
                {renderButtons()}
            </div>
        </Drawer>
    )
}