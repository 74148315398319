import { IClub } from '@/shared/models/Club';
import { getClubLogos } from '@/shared/utils/getClubUrl';
import Box from '@material-ui/core/Box';
import { useClub } from 'contexts/club';
import ResponsiveImage from './ResponsiveImage';

export type IResponsiveClubLogoProps = {
    className?: string
    size?: number
    externalClub?: IClub
}

export default function ResponsiveClubLogo(props: IResponsiveClubLogoProps) {
    const { club } = useClub()

    if(!props.externalClub?.logo_name && !club?.logo_name) {
        return null
    }

    // get only the last 2 images optimize for page displays
    const sources = getClubLogos(props.externalClub?.logo_name ? props.externalClub : club).slice(4)
    const fallbacks = sources.slice(0, -1)
    const mainSource = sources[sources.length - 1]
    const imageElement = (
        <ResponsiveImage
            sources={fallbacks}
            src={mainSource.src}
            alt={ club?.title ?? '' }
            {...props}
        />
    )
    if(props.size) {
        return (
            <Box mx={2} alignItems={'center'} style={{ width: props.size, }}>
                {imageElement}
            </Box>
        )
    }
    return imageElement
}