import React from 'react'
import { useRouter } from 'next/router'
import { FormattedMessage } from 'react-intl'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import TextButton from './TextButton'
import { IButtonProps } from './MButton'

export default function BackButton({
    onClick,
    href,
    children,
    ...rest
}: IButtonProps ): React.ReactElement {    
    const router = useRouter()

    return (
        <TextButton
            onClick={!href ? (onClick ? onClick : router.back) : null}
            startIcon={<ArrowBackIosIcon />}
            href={href}
            {...rest}
        >
            {children ? children : <FormattedMessage id='form.back' defaultMessage='Back' />}
        </TextButton>
    )
}