import React from 'react'
import { shadowStyle, blackShadowStyle } from './Avatar'

interface ISvgHexagonShapeProps {
    size: number;
    withShadow?: boolean;
    image?: string;
    color?: string;
    blackShadow?: boolean;
}

export default function SvgHexagonShape({ size, withShadow = false, image, color = 'black', blackShadow = false }: ISvgHexagonShapeProps): JSX.Element {
    // drawnPath of hexagon shape (with border radius 10) 
    // get from svg hexagon generation https://codepen.io/wvr/pen/WrNgJp (d param) 
    const drawnPath = 'M73.61215932167728 7.499999999999999Q86.60254037844386 0 99.59292143521044 7.499999999999999L160.21469970012114 42.5Q173.20508075688772 50 173.20508075688772 65L173.20508075688772 135Q173.20508075688772 150 160.21469970012114 157.5L99.59292143521044 192.5Q86.60254037844386 200 73.61215932167728 192.5L12.99038105676658 157.5Q0 150 0 135L0 65Q0 50 12.99038105676658 42.5Z'
    const getRandomId = Math.floor(Math.random() * 10000000)

    return (
        <svg className="svg-graphic" width={size} height="100%" viewBox="0 0 173.20508075688772 200" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" version="1.1"
            style={
                withShadow ? 
                    blackShadow ? blackShadowStyle : shadowStyle 
                    : {}}
        >
            {image && (
                <defs>
                    <pattern id={`img-${getRandomId}`} height="100%" width="100%" patternContentUnits="objectBoundingBox" viewBox="0 0 1 1" preserveAspectRatio="xMidYMid slice">
                        <rect x="0" y="0" width="100%" height="100%" fill="white"/>
                        <image height="1" width="1" preserveAspectRatio="xMidYMid slice" xlinkHref={image} />
                    </pattern> 
                </defs>
            )}
            <path fill={image ? `url(#img-${getRandomId})` : color} d={drawnPath}></path>
        </svg>
    )
}