import React, { useEffect, useMemo, useState } from 'react'
import { useAuth } from 'contexts/auth'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

// import LanguageSelector from 'components/LanguageSelector/LanguageSelector'
import { isEmpty } from '@/shared/utils/lodashFunc'
import { useTranslations } from 'contexts/translations'
import { getTranslation } from 'services/translations'
import { IMessages } from '@/shared/models/Messages'
import Footer from './WebsiteFooter/Footer'
import UserTopNavBar from './UserTopNavBar/UserTopNavBar'
import WebsiteNavBar from './WebsiteNavBar/WebsiteNavBar'
import NavigationThemeProvider from 'components/ThemeProvider/NavigationThemeProvider'
import { ITheme } from '@/shared/styles/MuiTheme'
import { useRouter } from 'next/router'
import Loader from '@/shared/components/Loader/Loader'
import { IWebsiteMenu } from '@/shared/models/Website'
import { useClub } from 'contexts/club'
import useElementSize from '@/shared/hooks/useElementSize'
import ContainerHeightContext from 'contexts/containerHeight'

const layoutStyles = makeStyles((theme: ITheme) => ({
    core: {
        height: '100%',
    },
    outputContainer: {
        maxWidth: '900px',
        margin: '0 auto',
        padding: '0 20px',
    },
    backBtn: {
        margin: '1rem 0 0 0',
    },
    wrapper: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
        },
        '& > svg': {
            display: 'none', //NOTE in first page load mui spinner appears inside div - we hide it forcibly
        }
    },
    content: {
        opacity: 1,
        transition: 'opacity 0.4s ease-in-out',
    },
    contentFadeOut: {
        opacity: 0,
    }
}))

interface IProps {
    children: React.ReactNode
    backButton?: JSX.Element
    hideUserTopNavBar?: boolean
    isPreview?: boolean
    isDefaultPreview?: boolean
    previewMenus?: IWebsiteMenu[]
}

const MainLayout = ({ children, backButton, hideUserTopNavBar, isPreview, previewMenus, isDefaultPreview }: IProps): JSX.Element => {
    const layoutClasses = layoutStyles()
    const { menu, token, isUserAdmin} = useAuth()
    const { translations: { locale }, extendTranslations} = useTranslations()
    const [ isRouteChanging, setIsRouteChanging ] = useState(false)
	const router = useRouter()
    const { website } = useClub()
    const [headerRef, { height: headerHeight }] = useElementSize()
    const [footerRef, { height: footerHeight }] = useElementSize()
    const [contentRef, { height: contentHeight }] = useElementSize()
    useEffect(() => {
		const handleRouteChangeStart = () => {
			// App is changing to ${url} ${shallow ? 'with' : 'without'} shallow routing
			setIsRouteChanging(true)
		}
		const handleRouteChangeComplete = () => {
			// App is finished changing to ${url} ${shallow ? 'with' : 'without'} shallow routing
			setIsRouteChanging(false)
		}
		const handleRouteChangeError = (err) => {
			if (err.cancelled) {
				// Route to ${url} was cancelled!
			}
			setIsRouteChanging(false)
		}

		router.events.on('routeChangeStart', handleRouteChangeStart)
		router.events.on('routeChangeComplete', handleRouteChangeComplete)
		router.events.on('routeChangeError', handleRouteChangeError)

		// If the component is unmounted, unsubscribe
		// from the event with the `off` method:
		return () => {
			router.events.off('routeChangeStart', handleRouteChangeStart)
			router.events.off('routeChangeComplete', handleRouteChangeComplete)
			router.events.off('routeChangeError', handleRouteChangeError)
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
    
    useEffect(() => {
        if (token && isUserAdmin && !isEmpty(menu)) {
            const placeholders: IMessages = {}
            menu.columns.forEach(cur => {
                cur.categories.forEach(category => {
                    placeholders[category.name] = category.name
                    if (category.items) {
                        category.items.forEach(child => {
                            placeholders[child.name] = child.name
                        })
                    }
                })
            })

            getTranslation(locale, placeholders)
                .then(messages => {
                    if (!isEmpty(messages)) {
                        extendTranslations({[locale]: messages})
                    }
                })
                .catch(error => {
                  if (process.env.NODE_ENV === 'development') {
                    console.log(error)
                    throw error
                  }
                })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu])

    const minHeight = useMemo(() => {
        if(!headerHeight || !footerHeight) {
            return undefined
        }
        return `calc(100vh - ${ headerHeight + footerHeight}px)`
    }, [headerHeight, footerHeight])

    return (
        <div id='core' className={layoutClasses.core}>
            <div className={layoutClasses.wrapper}>
                <div ref={headerRef}>
                    {!hideUserTopNavBar && <UserTopNavBar />}
                    {(isPreview || website?.new_website) && (
                        <NavigationThemeProvider>
                            <WebsiteNavBar hideUserTopNavBar={hideUserTopNavBar} isPreview={isPreview} previewMenus={previewMenus}/>
                        </NavigationThemeProvider>
                    )}
                </div>
                {backButton ? (
                    <Grid className={layoutClasses.outputContainer} container justifyContent='space-between'>
                        <div className={layoutClasses.backBtn}>{backButton}</div>
                    </Grid>
                ) : ''}
                {isRouteChanging ? <Loader mods='center' /> : ''}
                <ContainerHeightContext.Provider value={contentHeight}>
                    <div ref={contentRef} style={{ minHeight }} className={`${layoutClasses.content}${isRouteChanging ? ` ${layoutClasses.contentFadeOut}` : ''}`}>
                        {children}
                    </div>
                </ContainerHeightContext.Provider>
            </div>
            {((isPreview || website?.new_website) && !isDefaultPreview) && (
                <div ref={footerRef}>
                    <NavigationThemeProvider>
                        <Footer />
                    </NavigationThemeProvider>
                </div>
            )}
        </div>
    )
}

export default MainLayout