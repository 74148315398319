import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

import PrimaryButton from './PrimaryButton'
import { IButtonProps } from './MButton'
import SecondaryButton from './SecondaryButton'

export const useButtonLinkStyles = makeStyles(() => ({
    textDecorationNone: {
        textDecoration: 'none'
    },
    wordBreak: {
        wordBreak: 'break-word',
    },
}))

export interface IButtonLinkProps extends IButtonProps {
    openInNewWindow: boolean
    href: string
    isPrimary?: boolean
    className?: string
}

export default function ButtonLink({ children, href, openInNewWindow, isPrimary = true, className = '', ...rest }: IButtonLinkProps): JSX.Element {
    const classes = useButtonLinkStyles()
    const linkProps = openInNewWindow ? { target: '_blank', rel: 'noreferrer' } : {}

    return (
        <a href={href} className={classes.textDecorationNone} {...linkProps}>
            {isPrimary ? (
                <PrimaryButton isAccent className={`${classes.wordBreak} ${className}`} {...rest}>
                    {children}
                </PrimaryButton>
            ) : (
                <SecondaryButton className={`${classes.wordBreak} ${className}`} {...rest}>
                    {children}
                </SecondaryButton>
            )}
        </a>
    )
}
