import { makeStyles, createStyles } from '@material-ui/core/styles'

import { ITheme } from '@/shared/styles/MuiTheme'
import Typography from '@material-ui/core/Typography/Typography'

interface IStyleProps {
    isAccent?: boolean;
    variant: string;
    isMobile?: boolean;
}

const typographyStyles = makeStyles((theme: ITheme) => createStyles({
    typography: {
        color: ({ isAccent }: IStyleProps) => isAccent ? theme.props.sectionMode.text_accent : theme.props.sectionMode.text_background,
        fontSize: ({ variant, isMobile }: IStyleProps) => {
            if (isMobile) {
                return `${theme.typography[variant][theme.breakpoints.down('sm')].fontSize} !important`
            }
            return theme.typography[variant].fontSize
        },
        lineHeight: ({ variant, isMobile }: IStyleProps) => {
            if (isMobile) {
                return `${theme.typography[variant][theme.breakpoints.down('sm')].lineHeight} !important`
            }
            return theme.typography[variant].lineHeight
        },
        [theme.breakpoints.down('sm')] : {
            lineHeight: ({ variant }: IStyleProps) => theme.typography[variant][theme.breakpoints.down('sm')].lineHeight ,
            fontSize:  ({ variant }: IStyleProps) => theme.typography[variant][theme.breakpoints.down('sm')].fontSize
        },
        wordBreak: 'break-word'
    },
}))

interface IProps {
    children: React.ReactNode | string;
    isAccent?: boolean; //NOTE pass true if font color should take text_accent color
    isMobile?: boolean; //NOTE pass true to force a mobile font size
    className?: string;
}

export function Headline1 ({ children, isAccent, isMobile, className = ''}: IProps): JSX.Element {
    const classes = typographyStyles({ isAccent, variant: 'h1', isMobile, })
    const additionalClassName = className ? ` ${className}` : ''
    
    return <Typography className={classes.typography + additionalClassName} variant="h1">{children}</Typography>
}

export function Headline2 ({ children, isAccent, isMobile, className = ''}: IProps): JSX.Element {
    const classes = typographyStyles({ isAccent, variant: 'h2', isMobile, })
    const additionalClassName = className ? ` ${className}` : ''
    return <Typography className={classes.typography + additionalClassName} variant="h2">{children}</Typography>
}

export function Headline3 ({ children, isAccent, isMobile, className = ''}: IProps): JSX.Element {
    const classes = typographyStyles({ isAccent, variant: 'h3', isMobile, })
    const additionalClassName = className ? ` ${className}` : ''
    
    return <Typography className={classes.typography + additionalClassName} variant="h3">{children}</Typography>
}

export function Headline4 ({ children, isAccent, isMobile, className = ''}: IProps): JSX.Element {
    const classes = typographyStyles({ isAccent, variant: 'h4', isMobile, })
    const additionalClassName = className ? ` ${className}` : ''
    
    return <Typography className={classes.typography + additionalClassName} variant="h4">{children}</Typography>
}

export function Paragraph1 ({ children, isAccent, isMobile, className = ''}: IProps): JSX.Element {
    const classes = typographyStyles({ isAccent, variant: 'body1', isMobile, })
    const additionalClassName = className ? ` ${className}` : ''
    
    return <Typography className={classes.typography + additionalClassName} variant="body1">{children}</Typography>
}

export function Paragraph2 ({ children, isAccent, isMobile, className = ''}: IProps): JSX.Element {
    const classes = typographyStyles({ isAccent, variant: 'body2', isMobile, })
    const additionalClassName = className ? ` ${className}` : ''

    return <Typography className={classes.typography + additionalClassName} variant="body2">{children}</Typography>
}