import { makeStyles } from '@material-ui/core/styles'

export const avatarStyles = makeStyles({
    root: {},
    picBody: {
        backgroundPosition: 'top center',
        backgroundSize: 'cover',
    },
    initialsBody: {
        fontSize: '16px',
        '&--hexagone': {
            position: 'relative',
            '& $initialsText': {
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }
        },
        '&--round': {
            borderRadius: '50%',
        },
        '&--size-35': {
            width: '35px',
            height: '35px',            
            '& $initialsText': {
                lineHeight: '35px',
            }
        }
    },
    initialsText: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    whiteHexagon: {
        position: 'relative',
        zIndex: 1,
        '& img': {
            position: 'absolute',
            top: '53%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }
    }
})