import { IImageSource } from '@/shared/models/Image'

type IResponsiveImageProps = {
    sources: IImageSource[],
    className?: string
    alt?: string
    src: string
    style?: { [key: string]: any }
}
export default function ResponsiveImage(props: IResponsiveImageProps) {
    const { sources, className, alt, src, style = { maxWidth: '100%', maxHeight: '100%' } } = props
    return (
        <picture className={className}>
            {sources.map(e => (
                <source key={`${e.width}-${e.mime}`} media={`(max-width: ${e.width}px)`} srcSet={e.src} type={e.mime}/>
            ))}
            {/* image to display on unspecified resolutions */}
            <img className={className} style={style} alt={alt} src={src} loading='lazy' /> 
        </picture>
    )
}