import moment from 'moment'

export const getPackageTargeting = (packageName: string): string => {
    const matches = packageName.match(/\b(\w)/g)
    return matches.join('')
}

export const getUserAgeTargeting = (date: string): number => {
    const ageLimits = [0, 10, 15, 18, 25, 30, 40, 50, 60]

    try {
        const age = moment().diff(date, 'years')

        if (age === 10) {
            return 1
        }

        if (age > 60) {
            return 9
        }

        if (age < 10) {
            return 10
        }

        const nearestMin = Math.max(...ageLimits.filter(v => v < age))
        return ageLimits.findIndex(limit => limit === nearestMin)+1

    } catch (e) {
        return 10
    }
}

export function destroyAdSlots(): void {
    if (typeof window !== 'undefined') {
        const googletag = (window as any).googletag ?? { cmd: [] }
        if (typeof googletag?.destroySlots !== 'undefined') {
            googletag.destroySlots()
        }
    }
}

export const detectColorset = (hex: string): string => {
    const color = hex.replace('#', '')
    const rgb = parseInt(color, 16)
    const r = (rgb >> 16) & 0xff
    const g = (rgb >> 8) & 0xff
    const b = (rgb >> 0) & 0xff
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

    return luma < 128 ? 'dark' : 'light'
}