import React, { useState } from 'react'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import { useAuth } from 'contexts/auth'
import { isEmpty } from '@/shared/utils/lodashFunc'
import AdminMenuColumns from './AdminMenuColums'
import { menuStyles } from './styles'

export default function AdminMenu({
    isMenuOpen
}: {
    isMenuOpen: (value: boolean) => void
}): JSX.Element {
    const [ menuIsOpen, setMenuIsOpen ] = useState(false)
    const menuClasses = menuStyles()
    const authContext = useAuth()
    const { menu, isUserAdmin} = authContext

    function toggleMenu() {
        setMenuIsOpen(!menuIsOpen)
        isMenuOpen(!menuIsOpen)
    }

    if (!isUserAdmin || isEmpty(menu)) {
        return null
    }

    return (
        <div className={menuClasses.menu}>
            <div className={menuClasses.menuContainer}>
                <div className={menuClasses.menuToggle} onClick={toggleMenu}>
                    {menuIsOpen ?
                        <img src={`${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}/shared/burger_close_icon.svg`} height='22' alt='' />
                        : <img src={`${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}/shared/burger_open_icon.svg`} height='28' alt='' />
                    }
                </div>
                {menuIsOpen && (
                    <div className={menuClasses.menuContent}>
                        <div className={menuClasses.container}>
                            <AdminMenuColumns menu={menu} />
                        </div>
                        <a className={menuClasses.menuCloseBtn} onClick={toggleMenu}><ExpandLessIcon style={{ fontSize: 50 }} /></a>
                    </div>
                )}
            </div>
        </div>
    )
}