export default function applyMods(mainClass: string, mods = ''): string {
    const modClasses = mods.split('|')
        .filter(item => !!item)
        .map(item => `${mainClass}--${item}`)

    let newClasses = mainClass
    if (modClasses.length > 0) {
        newClasses += ` ${modClasses.join(' ')}`
    }
    return newClasses
}