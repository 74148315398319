export function generateMobileUrl(token: string, language: string): string {
    let lang = 'en_us'

    if (language === 'fr') lang = 'fr_fr'
    if (language === 'de') lang = 'de_de'
    if (language === 'fi') lang = 'fi_fi'
    if (language === 'es') lang = 'es_es'
    if (language === 'nl') lang = 'nl_nl'
    if (language === 'lu') lang = 'de-lu'

    return `${process.env.NEXT_PUBLIC_MOBILE_CLUBEE_URL}/?token=${token}&lang=${lang}`
}