import { createTheme, MuiThemeProvider, useTheme } from '@material-ui/core/styles'
import React from 'react'

import { ITheme, overridesMuiButton } from '@/shared/styles/MuiTheme'
import { useClub } from 'contexts/club'
import { mockModeColors } from './CustomThemeProvider'

interface IProps {
    children: React.ReactNode;
}

export default function NavigationThemeProvider({
    children,
}: IProps): JSX.Element {
    const { modes, website } = useClub()
    const theme: ITheme = useTheme()
    const currentNavMode = modes?.find(mode => mode.type === website.navigation_color_mode) ?? mockModeColors

    const sectionTheme = createTheme(theme, { //NOTE deep merge theme with new props
		props: {
            sectionMode: {...currentNavMode}
        },
        palette: {
			primary: {
				light: currentNavMode.accent,
				main: currentNavMode.accent,
				dark: currentNavMode.accent,
			},
			secondary: {
				light: currentNavMode.text_background,
				main: currentNavMode.text_background,
				dark: currentNavMode.text_background,
			}
		},
        overrides: {
			MuiButton: {
                ...overridesMuiButton(currentNavMode)
			},
            MuiTabs: {
                indicator: {
                    display: 'none', //NOTE to hide indicator in desktop menu
                },
                scroller: {
                    position: 'static',
                }
            },
            MuiDrawer: {
                paper: {
                    width: '98%',
                },
                paperAnchorRight: {
                    borderRadius: '8px 0 0 8px'
                },
                paperAnchorLeft: {
                    borderRadius: '0 8px 8px 0'
                },
            }
        }
    })

    return (
        <MuiThemeProvider theme={sectionTheme}>
            {children}
        </MuiThemeProvider>
    )
}