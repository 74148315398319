import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { ITheme } from '@/shared/styles/MuiTheme'
import ClubLogo from './Elements/ClubLogo'
import ClubName from './Elements/ClubName'
import LoginButton from './Elements/LoginButton'
import MobileMenuButton from './Elements/MobileMenuButton'

const middleLogoStyles = makeStyles((theme: ITheme) => ({
    navClub: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 3),
            flexDirection: 'row-reverse',
            justifyContent: 'center'
        }
    },
    navClubLogo: {
        height: '54px',
        width: '54px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: theme.spacing(4),
        position: 'absolute',
        left: '50%',
        zIndex: 1,
        '& img': {
            maxHeight: '54px',
            maxWidth: '54px',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            left: 0,
        }
    },
    navClubName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 450,
        whiteSpace: 'normal',
        '& h3': {
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
        },
    },
    loginBtn: {
        display: 'flex',
        '& button': {
            border: `1px solid ${theme.props.sectionMode.text_accent}`,
            '&:hover': {
                border: `1px solid ${theme.props.sectionMode.text_accent}`,
            }
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    menuBtn: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        }
    }
}))

interface IProps {
    handleMobileMenuOpen: () => void
}

export default function HeaderMiddleLogo({ handleMobileMenuOpen }: IProps): JSX.Element {
    const classes = middleLogoStyles()

    return (
        <>
            <div className={classes.menuBtn}>
                <MobileMenuButton onClick={handleMobileMenuOpen} />
            </div>
            <div className={classes.navClub}>
                <div className={classes.navClubName}>
                    <ClubName useAbbreviationOfTitle />
                </div>
                <div className={classes.navClubLogo}>
                    <ClubLogo />
                </div>
            </div>
            <div className={classes.loginBtn} >
                <LoginButton />
            </div> 
        </>
    )
}