import ButtonLink, { IButtonLinkProps } from '@/shared/components/Button/ButtonLink';
import { CLUBEE_BLACK } from '@/shared/constants';
import makeStyles from '@material-ui/styles/makeStyles';

const useFlfNavLinkButtonStyles = makeStyles(() => ({
    root: {
        borderColor: CLUBEE_BLACK,
        '&:hover': {
            borderColor: CLUBEE_BLACK,
        },
        '& h4': {
            color: `${CLUBEE_BLACK} !important`
        },
        '& img': {
            filter: 'unset !important'
        }
    }
}))

export default function FlfNavLinkButton(props: IButtonLinkProps) {
    const classes = useFlfNavLinkButtonStyles()

    return (
        <ButtonLink {...props} isPrimary={false} size='small' className={classes.root} />
    )
}
