import moment from 'moment'
import { EVENT_TYPE_GAME, IEvent } from '../models/Event'
import { IGame } from '../models/Game'
import { INews } from '../models/News'
import { IVideo } from '../models/Video'
import { IComposedFooterLink, IFooterLink, IWebsitePage } from '../models/Website'
import { isUndefined } from './lodashFunc'

export const checkEventInPast = (eventInfo: IEvent): boolean => {
	return eventInfo.type === EVENT_TYPE_GAME ?
		moment(eventInfo.start_date).add(2, 'hours').isBefore(moment()) : moment(eventInfo.end_date).isBefore(moment())
}

export const checkGameInPast = (eventInfo: IGame): boolean => {
	return 	moment(eventInfo.start_date).add(2, 'hours').isBefore(moment())
}

export const checkFutureGame = (game: IGame): boolean => {
	const offset = moment().local().utcOffset() / 60
    return moment(game?.start_date).isAfter(moment().add(offset, 'hours'))
}

export const displayLiveStreamButton = (eventInfo: IEvent | IGame): boolean => {
	return eventInfo?.stream_link && moment(eventInfo?.start_date).isAfter(moment()) && moment().add(2, 'hours').isAfter(moment(eventInfo?.start_date))
}

export const displayReplayButton = (eventInfo: IEvent | IGame): boolean => {
	return eventInfo?.stream_link && moment().add(2, 'hours').isAfter(moment(eventInfo?.start_date))
}

export const getVideoUrl = (video: IVideo): string => {
	if(video?.host === 'youtube') {
		return `https://www.youtube.com/watch?v=${video.provider_id}`
	} else if(video?.host === 'vimeo') {
		return `https://vimeo.com/${video.provider_id}`
	} else {
		return ''
	}
}

export function displayGameScore(game: IGame) {
	const { score1, score2 } = game
	const hasScore = !isUndefined(score1) || !isUndefined(score2)
	return hasScore ? `${score1 ? score1 : 0} : ${score2 ? score2 : 0}`  : ' - : - '
}

export function hasScore(game: IGame): boolean {
	return !isUndefined(game?.score1) || !isUndefined(game?.score2)
}

export const downloadUrl = async (url: string, inNewTab: boolean = false): Promise<void> => {
	let anchor = document.getElementById('download-root') as HTMLAnchorElement
	anchor = document.createElement('a')
	anchor.id = 'download-root'
		anchor.href = url
	if (inNewTab) {
		anchor.target = '_blank'
	}
	document.body.appendChild(anchor)
		anchor.click()
	document.body.removeChild(anchor);
}

export function extractFilenameWithoutExtension(filename: string) {
    return filename.split('.').slice(0, -1)
}

export const gameSorter = (a: IGame, b: IGame) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
export const newsSorter = (a: INews, b: INews) => new Date(b.date*100).getTime() - new Date(a.date * 100).getTime()

export function composeFooterLinks(footerLinks: IFooterLink[] = [], pages: IWebsitePage[] = []): IComposedFooterLink[] {
	const composedLinks = []
	footerLinks.forEach(link => {
		if (link.page) {
			const page = pages.find(p => p.id === link.page.id)
			if (!page) return
			composedLinks.push({
				isPage: true,
				link: page.seo,
				name: page.title
			})
		} else {
			composedLinks.push({
				isPage: false,
				link: link.url,
				name: link.name
			})
		}
	})
	return composedLinks
}

export function checkDateInPast(date: string): boolean {
	const expirationDate = moment(date)
	const now = moment()
	return expirationDate.isBefore(now)
}