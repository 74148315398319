import { ITheme } from '@/shared/styles/MuiTheme'
import { makeStyles } from '@material-ui/core/styles'

interface IStyleProps {
    hexColor: string;
}

const imgStyles = makeStyles((theme: ITheme) => ({
    img : {
        filter: ({ hexColor }: IStyleProps) => hexColor && theme.props.colorFilters[hexColor] ? theme.props.colorFilters[hexColor] : ''
    }
}))

interface IProps {
    hexColor: string;
    src: string;
}

export default function Icon({ hexColor, src }: IProps): JSX.Element {
    const classes = imgStyles({ hexColor })

    return (
        <img className={classes.img} src={src} />
    )
}