import React from 'react'

import MButton, { IButtonProps } from './MButton'


export default function SecondaryButton({
    children,
    applyBackgroundColor,
    ...rest
}: IButtonProps): JSX.Element {
    return (
        <MButton
            applyBackgroundColor={applyBackgroundColor}
            variant='outlined'
            color='secondary'
            {...rest}
        >
            {children}
        </MButton>
    )
}