import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { ITheme } from '@/shared/styles/MuiTheme'
import ClubLogo from './Elements/ClubLogo'
import ClubName from './Elements/ClubName'
import LoginButton from './Elements/LoginButton'
import MobileMenuButton from './Elements/MobileMenuButton'
import { useClub } from 'contexts/club'
import { useIntl } from 'react-intl'
import { sectionMessages } from 'components/Sections/messages'
import { CLUBEE_BLACK, FLF_SHOP_ICON, FLF_SHOP_LINK, FLF_TICKETS_LINK, FLF_TICKET_ICON } from '@/shared/constants'
import { IFlfThemeProps } from '@/shared/models/Club'
import { AdManager } from '@/shared/components/AdManager/AdManager'
import FlfNavLinkButton from './Elements/FlfNavLinkButton'

const dimensionHandler = ({ isFlf }: IFlfThemeProps) =>  isFlf ? 88 : 96

const leftBigLogoStyles = makeStyles((theme: ITheme) => ({
    navClub: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 3),
        }
    },
    navClubLogo: {
        height: '54px',
        width: (props) => props.isFlf ? 80: 96,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: theme.spacing(6),
        [theme.breakpoints.down('lg')]: {
            width: (props) => props.isFlf ? 100: 96
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: (props: IFlfThemeProps) => props.isFlf ? 0 : 64
        },
    },
    navClubLogoBig: {
        position: 'absolute',
        zIndex: 1,
        left: theme.spacing(3),
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '114px',
        '& img': {
            height: dimensionHandler,
            width: dimensionHandler,
            objectFit: 'contain'
        },
        '&:before': {
            content: '""',
            top: '-16px',
            right: `-${theme.spacing(5)}px`,
            width: '146px',
            height: '146px',
            zIndex: -1,
            position: 'absolute',
            borderRadius: '70px',
            backgroundColor: ({ isFlf }: IFlfThemeProps) => !isFlf ? theme.props.sectionMode.background : undefined,
        },
        [theme.breakpoints.up('md')]: {
            width: dimensionHandler,
        },
        [theme.breakpoints.down('sm')]: {
            height: '64px',
            width: '64px',
            padding: 8,
            '& img': {
                maxHeight: '48px !important',
                maxWidth: '48px !important',
            },
            '&:before': {
                top: '-4px',
                content: '""',
                right: `-${theme.spacing(4)}px`,
                width: '200px',
                height: '80px',
            },
        },
    },
    navClubName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 500,
        whiteSpace: 'normal',
        '& h3': {
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            color: ({ isFlf }: IFlfThemeProps) => isFlf ? `${CLUBEE_BLACK} !important` : undefined,
        }
    },
    navClubNameInner: {
        [theme.breakpoints.down('lg')]: {
            lineHeight: '26px',
            marginRight: 5
        }
    },
    loginBtn: {
        display: 'flex',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    loginBtnWrapper: {
        marginLeft: 12
    },
    menuBtn: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        }
    },
    adRoot: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    addWrapper: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    }
}))

interface IProps {
    handleMobileMenuOpen: () => void
}

export default function HeaderLeftBigLogo({ handleMobileMenuOpen }: IProps): JSX.Element {
    const { club, isFlf } = useClub()
    const classes = leftBigLogoStyles({ isFlf })
    const intl = useIntl()
    const theme: ITheme = useTheme()

    function renderAdManager(blockId): JSX.Element {
        const adClub = club?.id ?? 0

        return (
            <div key={`${blockId}-ad`} className={classes.adRoot}>
                {adClub && (
                    <AdManager
                        adType='main'
                        blockId={blockId}
                        sizes={[[300, 50], [234, 60]]}
                        color={theme.props.sectionMode.background}
                    />
                )}
            </div>
        )
    }

    if (!club) {
        return null
    }

    return (
        <>
            <div className={classes.navClub}>
                {!!(club.logo_name) && (
                    <div className={classes.navClubLogo}>
                        <div className={classes.navClubLogoBig}>
                            <ClubLogo />
                        </div>
                    </div>
                )}
                <div className={classes.navClubName}>
                    <ClubName additionalClass={classes.navClubNameInner} useAbbreviationOfTitle />
                </div>
            </div>
            <div className={classes.addWrapper}>
                {!isFlf && !!club.id && renderAdManager(club.id)}
            </div>
            <div className={classes.loginBtn} >
                {isFlf ? (
                        <>  
                            <div className={classes.loginBtnWrapper}>
                                <FlfNavLinkButton openInNewWindow startImg imgSrc={FLF_SHOP_ICON} href={FLF_SHOP_LINK} key={1}>
                                    {intl.formatMessage(sectionMessages['navigation.btn.shop'])}
                                </FlfNavLinkButton>
                            </div>
                            <div className={classes.loginBtnWrapper}>
                                <FlfNavLinkButton openInNewWindow startImg imgSrc={FLF_TICKET_ICON} href={FLF_TICKETS_LINK} key={2}>
                                    {intl.formatMessage(sectionMessages['navigation.btn.tickets'])}
                                </FlfNavLinkButton> 
                            </div>
                        </>
                    ) : null
                }
                <div className={classes.loginBtnWrapper}>
                    <LoginButton key={3} />
                </div>
            </div>
            <div className={classes.menuBtn}>
                <MobileMenuButton onClick={handleMobileMenuOpen} />
            </div>
        </>
    )
}