import { Colors, Fonts, ITheme } from '@/shared/styles/MuiTheme';
import { makeStyles } from '@material-ui/core/styles'

export const barStyles = makeStyles((theme: ITheme) => ({
    header: {
        position: 'relative',
        zIndex: 10,
        background: theme.props.sectionMode.accent,
        fontFamily: Fonts.Nunito,
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(270deg, rgba(19, 19, 19, 0.2) 0%, rgba(19, 19, 19, 0) 100%)',
            zIndex: -1,
        }
    },
    headerBackground: {
        background: Colors.Secondary,
        borderRadius: '0 0 20px 20px',
        maxHeight: '100vh',
        transition: 'all 0.4s',
        '&--open': {
            borderRadius: 0,
        }
    },
    container: {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '0 15px'
    },
    inner: {
        display: 'flex',
        alignItems: 'center'
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '165px',
        height: '64px',
        marginRight: '20px',
        borderRight: '0.1rem solid rgba(255, 255, 255, 0.2)',
    },
    avatar : {
        flex: 1,
        textAlign: 'right',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        display: 'flex',
        '& > a': {
            display: 'flex',
        }
    }
}))