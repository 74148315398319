import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { AdManager } from '@/shared/components/AdManager/AdManager'
import { ITheme } from '@/shared/styles/MuiTheme'
import ClubLogo from './Elements/ClubLogo'
import ClubName from './Elements/ClubName'
import LoginButton from './Elements/LoginButton'
import MobileMenuButton from './Elements/MobileMenuButton'
import { useClub } from 'contexts/club'

const leftLogoStyles = makeStyles((theme: ITheme) => ({
    navClub: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 3),
        }
    },
    navClubLogo: {
        height: '54px',
        width: '54px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: theme.spacing(4),
    },
    navClubName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'normal',
            '& h3': {
                display: '-webkit-box',
                '-webkit-line-clamp': 2,
                '-webkit-box-orient': 'vertical',
            },
        }
    },
    loginBtn: {
        display: 'flex',
        marginLeft: 15,
        '& button': {
            border: `1px solid ${theme.props.sectionMode.text_accent}`,
            '&:hover': {
                border: `1px solid ${theme.props.sectionMode.text_accent}`,
            }
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    menuBtn: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        }
    },
    adRoot: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    addWrapper: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    }
}))

interface IProps {
    handleMobileMenuOpen: () => void
}

export default function HeaderLeftLogo({ handleMobileMenuOpen }: IProps): JSX.Element {
    const classes = leftLogoStyles()
    const { club } = useClub()
    const theme: ITheme = useTheme()

    function renderAdManager(blockId): JSX.Element {
        const adClub = club?.id ?? 0

        return (
            <div key={`${blockId}-ad`} className={classes.adRoot}>
                {adClub && (
                    <AdManager
                        adType='main'
                        blockId={blockId}
                        sizes={[[300, 50], [234, 60]]}
                        color={theme.props.sectionMode.background}
                    />
                )}
            </div>
        )
    }

    return (
        <>
            <div className={classes.navClub}>
                {Boolean(club.logo_name) && (
                    <div className={classes.navClubLogo}>
                        <ClubLogo />
                    </div>
                )}
                <div className={classes.navClubName}>
                    <ClubName useAbbreviationOfTitle />
                </div>
            </div>
            <div className={classes.addWrapper}>
                {renderAdManager(club.id)}
            </div>
            <div className={classes.loginBtn} >
                <LoginButton />
            </div>
            <div className={classes.menuBtn}>
                <MobileMenuButton onClick={handleMobileMenuOpen} />
            </div>
        </>
    )
}