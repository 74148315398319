import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab/Tab';
import Tabs from '@material-ui/core/Tabs/Tabs';
import { TabScrollButton, TabScrollButtonProps } from '@material-ui/core';

import { Colors, ITheme } from '@/shared/styles/MuiTheme'
import { IWebsiteMenu, IWebsiteMenuType, WebsiteButtonStyle, WebsiteLogoPosition } from '@/shared/models/Website'
import { Paragraph2 } from '@/shared/components/Typography'
import { isEmpty, isUndefined } from '@/shared/utils/lodashFunc';
import applyMods from '@/shared/utils/applyMods';
import { useClub } from 'contexts/club'
import { useAuth } from 'contexts/auth';
import { getMenuUrl, getSelectedFirstLevelMenu } from './utils';
import { CLUBEE_BLACK } from '@/shared/constants';
import MenuLink from './MenuLink';

interface IStylesProps {
    isUserLoggedIn: boolean;
    buttonStyle: WebsiteButtonStyle;
    hideUserTopNavBar?: boolean
    isFlf: boolean
}

const CLICKABLE_MENU_CLUBS = ['clickablemenu', 'flf']

const useStyles = makeStyles((theme: ITheme) => ({
    navMenu: {
        height: '40px',
        backgroundColor: (styleProps) => styleProps.isFlf ? '#FFFFFF' : theme.props.sectionMode.background,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        fontSize: '24px', //to apply it to scroll arrow icons
        [theme.breakpoints.down('sm')]: {
            display: 'none', //NOTE in this case we show mobile menu view
        },
        '& p' : {
            color: (styleProps) => styleProps.isFlf ? `${CLUBEE_BLACK} !important` : undefined
        }
    },
    navMenuInner: {
        color: (styleProps) => styleProps.isFlf ? CLUBEE_BLACK : theme.props.sectionMode.text_background,
        display: 'flex',
        '& a': {
            textDecoration: 'none'
        }
    },
    manuTabs: {
        minHeight: 'auto'
    },
    menuTab: {
        padding: 0,
        minHeight: 'auto',
        minWidth: 'auto',
        position: 'static',
        maxWidth: 'none'
    },
    firstLevelMenuItem: {
        position: 'static',
        height: '40px',
        padding: theme.spacing(0, 4),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        paddingBottom: '2px',
        '&--selected': {
            paddingBottom: 0,
            borderBottom:(styleProps) =>  `2px solid ${styleProps.isFlf ? Colors.FlfRedColor : theme.props.sectionMode.accent}`,
        },
        '&:hover': {
            '& $menuSubLevel': {
                visibility: 'visible',
                opacity: 1,
                overflow: 'visible',
                zIndex: 9999
            }
        }
    },
    secondLevelMenuTitle: {
        width: '140px',
        marginBottom: theme.spacing(4),
        whiteSpace: 'break-spaces',
        textAlign: 'left'
    },
    secondLevelMenuTitleFLF: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    moreIconSubLevelFLF: {
        marginLeft: '5px'
    },
    subLevelMenuBackgroundFlf: {
        background: 'linear-gradient(270deg, rgba(19, 19, 19, 0.2) 0%, rgba(255, 255, 255, 0) 99.2%)',
        maxWidth: 'unset',
        display: 'flex',
        justifyContent: 'center'
    },
    menuSublevelFlf: {
        maxWidth: 992
    },
    childLevelMenuItem: {
        width: '140px',
        marginBottom: theme.spacing(2),
        whiteSpace: 'break-spaces',
        textAlign: 'left'
    },
    expandIcon: {
        marginLeft: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color: (styleProps) => styleProps.isFlf ? CLUBEE_BLACK : theme.props.sectionMode.text_background,
        height: '38px',
        '& svg': {
            fontSize: '14px',
        }
    },
    menuSubLevel: {
        cursor: 'auto',
        position: 'absolute',
        width: '100%',
        overflowY: 'auto',
        left: 0,
        top: ({ isUserLoggedIn, hideUserTopNavBar, isFlf }: IStylesProps) => (!hideUserTopNavBar && isUserLoggedIn ? 178 : 114) - (isFlf ? 10 : 0),
        background: (styleProps) => styleProps.isFlf ? '#FFFFFF' : theme.props.sectionMode.card,
        zIndex: 1,
        opacity: 0,
        overflow: 'hidden',
        visibility: 'hidden',
        transition: 'visibility 0.4s ease-in-out, opacity 0.4s ease-in-out',
        borderRadius: ({ buttonStyle }: IStylesProps) => buttonStyle === WebsiteButtonStyle.SQUARE_CORNERS ? 0 : '0 0 8px 8px',
    },
    menuSubLevelFLF: {
        cursor: 'auto',
        position: 'absolute',
        width: '100%',
        overflowY: 'auto',
        left: 0,
        top: ({ isUserLoggedIn, hideUserTopNavBar, isFlf }: IStylesProps) => (!hideUserTopNavBar && isUserLoggedIn ? 178 : 114) - (isFlf ? 10 : 0),
        background: (styleProps) => styleProps.isFlf ? '#FFFFFF' : theme.props.sectionMode.card,
        zIndex: 1,
        opacity: 0,
        overflow: 'hidden',
        visibility: 'hidden',
        transition: 'visibility 0.4s ease-in-out, opacity 0.4s ease-in-out',
        borderRadius: ({ buttonStyle }: IStylesProps) => buttonStyle === WebsiteButtonStyle.SQUARE_CORNERS ? 0 : '0 0 8px 8px',
    },
    menuSubLevelVisibleFLF: {
        visibility: 'visible',
        opacity: 1,
        overflow: 'visible'
    },
    scrollButton: {
        height: '40px',
        backgroundColor: (styleProps) => styleProps.isFlf ? '#FFFFFF' : theme.props.sectionMode.background,
    },
    noPointerEvents: {
        pointerEvents: 'none'
    }
}))

interface IProps {
    hideUserTopNavBar?: boolean;
    isPreview?: boolean
    previewMenus?: IWebsiteMenu[]
}

export default function WebsiteDesktopMenu({ hideUserTopNavBar, isPreview, previewMenus }: IProps): JSX.Element {
    const [isMenuHidden, hideMenu] = useState(false)
    const [openedFirstLevelMenu, setOpenedFirstLevelMenu] = useState<number>(null)
    const [mounted, setMounted] = useState<boolean>(false)
    const [clickedSublevelMenu, setClickedSublevelMenu] = useState<number>(null)

    const { website, menu: currentMenus, pathPrefix, isFlf, club } = useClub()
    const menu = previewMenus || currentMenus
    const { user } = useAuth()
    const theme: ITheme = useTheme()
    const router = useRouter()
    const isBigLogo = website.logo_position === WebsiteLogoPosition.LEFT_BIG
    const isClickableMenu = CLICKABLE_MENU_CLUBS.some(suffix => suffix === club?.suffix)
    const classes = useStyles({ isUserLoggedIn: !!user, buttonStyle: website?.button_style, hideUserTopNavBar, isFlf })

    const pageSlug = isUndefined(router.query.slug) || isEmpty(router.query.slug) ? null : router.query.slug[0]
    const activeFirstLevelMenu = getSelectedFirstLevelMenu(router, pageSlug, menu)
    const activeFirstLevelMenuId = activeFirstLevelMenu ? activeFirstLevelMenu.id : 0

    useEffect(() => {
        setMounted(true)
    }, [])

    function renderFirstLevelItem(item: IWebsiteMenu, key: number): JSX.Element  {
        let isSelected = false
        if (activeFirstLevelMenuId && activeFirstLevelMenuId === item.id)  {
            isSelected = true
        }

        return (
            <div key={`menu-item-${key}`} className={applyMods(classes.firstLevelMenuItem, isSelected ? 'selected' : '' )}>
                <Paragraph2><div onClick={isClickableMenu ? () => toggleMenu(item, true) : null}>{isSelected ? <b>{item.name}</b> : item.name}</div></Paragraph2>
                {mounted && renderSubLevelMenu(item)}
            </div>
        ) 
    }    

    function renderFirstLevelMenu(item: IWebsiteMenu, key: number): JSX.Element {
        const href =  getMenuUrl(item, pathPrefix, isPreview)

        if (href) {
            return (
                <Tab
                    key={item.id}
                    className={classes.menuTab}
                    disableRipple
                    label={renderFirstLevelItem(item, key)}
                    value={`${item.id}-menu`}
                    component={(props) => (
                        <MenuLink native={item.type === IWebsiteMenuType.LINK || item.url === 'va/shop_overview'} openInNewTab={item.blank} href={href} {...props}/>
                    )}
                />
            )
        }

        return <Tab
            key={key}
            className={classes.menuTab}
            disableRipple
            label={renderFirstLevelItem(item, key)}
            value={`${item.id}-menu`}
        />
    }

    function hideMenuOnClick(): void {
        if (isClickableMenu) {
            setClickedSublevelMenu(null)
            setOpenedFirstLevelMenu(null)
        }
        hideMenu(true)
        setTimeout(() => {
            hideMenu(false)
        }, 500)
    }

    function openSublevelMenu(menuId: number) {
        if (clickedSublevelMenu === menuId) return setClickedSublevelMenu(null)
        setClickedSublevelMenu(menuId)
    }

    function renderSubLevelTitle(item: IWebsiteMenu): JSX.Element {
        const href = getMenuUrl(item, pathPrefix, isPreview)
        const element = (
            <div onClick={isClickableMenu && item.type === 'drop-down' ? () => openSublevelMenu(item.id) : hideMenuOnClick} className={`${classes.secondLevelMenuTitle} ${isClickableMenu && classes.secondLevelMenuTitleFLF}`}>
                <Paragraph2><b>{item.name}</b></Paragraph2>
                {(isClickableMenu && Boolean(item.children?.length)) && (
                    <ExpandMoreIcon
                        className={classes.moreIconSubLevelFLF}
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            openSublevelMenu(item.id)
                        }}
                    />
                )}
            </div>
        )
        if (href) {
            return (
                <MenuLink key={item.id} native={item.type === IWebsiteMenuType.LINK} openInNewTab={item.blank} href={href}>
                    {element}
                </MenuLink>
            )
        }

        return element
    }

    function renderSubLevelMenuList(item: IWebsiteMenu): JSX.Element | null {
        if (isEmpty(item.children) || (isClickableMenu && item.id !== clickedSublevelMenu)) {
            return null
        }

        const element = (mItem: IWebsiteMenu, key: number) => <div onClick={hideMenuOnClick} key={key} className={classes.childLevelMenuItem}><Paragraph2>{mItem.name}</Paragraph2></div>

        return (
            <>
                {item.children.map((childSubMenu, index) => {
                    const href =  getMenuUrl(childSubMenu, pathPrefix, isPreview)
                    if (href) {
                        return (
                            <MenuLink openInNewTab={childSubMenu.blank} native={childSubMenu.type === IWebsiteMenuType.LINK} href={href} key={childSubMenu.id}>
                                {element(childSubMenu, index)}
                            </MenuLink>
                        )
                    }
                    return element(childSubMenu, index)
                })}
            </>
        )
    }

    function toggleMenu(item: IWebsiteMenu, clickOnName = false) {
        if (item.type === 'page' && clickOnName && openedFirstLevelMenu) return setOpenedFirstLevelMenu(null)
        if (item.type === 'page' && clickOnName) return

        if (item.type === 'drop-down' && !item.children?.length) return
        if (item.id === openedFirstLevelMenu) return setOpenedFirstLevelMenu(null)
        setOpenedFirstLevelMenu(item.id)
    }

    function renderSubLevelMenu(item: IWebsiteMenu): JSX.Element {
        if (isEmpty(item.children) && item.type !== IWebsiteMenuType.DROP_DOWN) {
            return null
        }

        return (
            <>
                <div className={classes.expandIcon}>
                    <ExpandMoreIcon onClick={isClickableMenu ?
                        (e) => {
                            if (!item.children?.length) return
                            e.preventDefault()
                            toggleMenu(item)
                        } : null}
                    />
                </div>
                <div className={`${isClickableMenu ? classes.menuSubLevelFLF : classes.menuSubLevel} ${isMenuHidden && classes.noPointerEvents} ${isClickableMenu && openedFirstLevelMenu === item.id ? classes.menuSubLevelVisibleFLF : ''}`}>
                    {!isEmpty(item.children) && (
                        <Container maxWidth="lg" style={{ padding: theme.spacing(4)}} className={`${isClickableMenu && classes.subLevelMenuBackgroundFlf}`}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={5} className={`${isClickableMenu && classes.menuSublevelFlf}`}>
                                {item.children.map((childMenu, index) => {
                                    return (
                                        <Grid item key={index}>
                                            {renderSubLevelTitle(childMenu)}
                                            {isClickableMenu ?
                                                clickedSublevelMenu && renderSubLevelMenuList(childMenu) :
                                                renderSubLevelMenuList(childMenu)
                                            }
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Container>
                    )}
                </div>
            </>
        )
    }

    function ScrollButton(props: TabScrollButtonProps): React.ReactElement {
        return <TabScrollButton className={classes.scrollButton} onClick={props.onClick} disabled={props.disabled} style={{ opacity: props.disabled ? 0 : 1, right: props.direction === 'right' ? 0 : null, position: props.direction === 'left' ? 'absolute' : 'unset' }} direction={props.direction} orientation={props.orientation} />
    }

    function renderMenu(): JSX.Element {
        if (!menu) {
            return null
        }

        return (
            <Tabs
                className={classes.manuTabs}
                value={activeFirstLevelMenuId ? `${activeFirstLevelMenuId}-menu` : false}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="desktop"
                aria-label="desktop menu"
                ScrollButtonComponent={ScrollButton}
            >
                <nav>
                    {menu.map((item, index) => {
                        return renderFirstLevelMenu(item, index)
                    })}
                </nav>
            </Tabs>
        )
    }

    return (
        <div className={classes.navMenu} >
            <Container maxWidth="lg" style={(isBigLogo || isFlf) ? {paddingLeft: isFlf ? 100: 140} : {}} className={classes.navMenuInner}>
                {renderMenu()}
            </Container>
        </div>
    )
}