import React, { useMemo } from 'react'

import { Headline3 } from '@/shared/components/Typography'
import { useClub } from 'contexts/club'
import { getClubUrl } from '@/shared/utils/getClubUrl'
import ElementLink from '@/shared/components/ElementLink/ElementLink'
import makeStyles from '@material-ui/styles/makeStyles';
import { ITheme } from '@/shared/styles/MuiTheme';

type IClubNameProps = {
    isAccent?: boolean
    additionalClass?: string
    useAbbreviationOfTitle?: boolean
}
const useClubNameStyles = makeStyles((theme: ITheme) => ({
    title: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    titleMobile: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block'
        }
    }
}))

export default function ClubName(props: IClubNameProps): JSX.Element {
    const { isAccent = true, additionalClass = '', useAbbreviationOfTitle = false } = props
    const { club, website } = useClub()
    const classes = useClubNameStyles()

    const link = useMemo(() => club ? getClubUrl(club) : '', [club])

    return (
        <ElementLink link={link}>
            <Headline3 className={additionalClass} isAccent={isAccent}>
                <div className={`${useAbbreviationOfTitle ? classes.title : ''}`}>{club.title}</div>
                {useAbbreviationOfTitle && <div className={`${classes.titleMobile}`}>{website?.abbreviation || club.title}</div>}
            </Headline3>
        </ElementLink>
    )
}