import React from 'react'

import { InitialsAvatar } from './InitialsAvatar'
import SvgHexagonShape from './SvgHexagonShape'
import { avatarStyles } from './styles'

export const DEFAULT_USER_ICON = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}/${process.env.NEXT_PUBLIC_DEFAULT_AVATAR_ICON}`
export const DEFAULT_CLUB_LOGO = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}/${process.env.NEXT_PUBLIC_DEFAULT_CLUB_LOGO}`
export const DEFAULT_TEAM_LOGO = `${process.env.NEXT_PUBLIC_DEFAULT_LOGO_ICON}`
export const DEFAULT_USER_ICON_OLD = process.env.NEXT_PUBLIC_DEFAULT_AVATAR_ICON_OLD

export const shadowStyle = {'filter': 'drop-shadow(rgb(195, 207, 231) 0px 2px 4px)'}
export const blackShadowStyle = {'filter': 'drop-shadow(rgb(0, 0, 0) 0px 2px 4px)'}

interface IAvatarProps {
    url?: string;
    type?: 'user'|'club'|'group';
    isHexagon?: boolean;
    size?: number;
    name?: string;
    useInitialsByDefault?: boolean;
    withShadow?: boolean;
    blackShadow?: boolean;
    inWhiteHexagon?: boolean;
}

function getDefautlPictureByType(type = ''): string {
    switch(type) {
        case 'user':
            return DEFAULT_USER_ICON
        case 'club':
            return DEFAULT_CLUB_LOGO
        case 'group': 
            return process.env.NEXT_PUBLIC_DEFAULT_LOGO_ICON
        default:
            // NOTE mascot icon by default
            return `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}/dashboard/mascot.svg`
    }
}

export function Avatar({
    url,
    type,
    isHexagon = false,
    size = 35,
    name,
    useInitialsByDefault = false,
    withShadow = false,
    blackShadow = false,
    inWhiteHexagon = false
}: IAvatarProps): JSX.Element {
    const classes = avatarStyles()
    let avatarUrl = url || getDefautlPictureByType(type)

    if (avatarUrl.includes('userpics/nouserpic.gif')) { // NOTE in dashboard we still have wrong default image which are saved in mongo
        avatarUrl = getDefautlPictureByType(type)
    }

    const isDefaultPicture = [
        DEFAULT_USER_ICON,
        process.env.NEXT_PUBLIC_DEFAULT_LOGO_ICON,
        DEFAULT_CLUB_LOGO
    ].includes(avatarUrl)    

    if (useInitialsByDefault && (!url || isDefaultPicture) && name) {
        return <InitialsAvatar name={name} isHexagon={isHexagon} size={size} withShadow={withShadow} />
    }
    
    if (isDefaultPicture && avatarUrl !== DEFAULT_USER_ICON_OLD) {
        // NOTE the default pictures alredy have some shape, we don't need to apply hexagon for them
        // NOTE for old default user picture we need to apply shape
        isHexagon = false
    }

    return (
        <div className={classes.root}>
            {
                isHexagon ?
                    inWhiteHexagon ?
                        <div className={classes.whiteHexagon}>
                            <SvgHexagonShape color='white' size={size} withShadow={withShadow} blackShadow={blackShadow} />
                            <img src={avatarUrl} alt='' style={{maxHeight: `${size-10}px`, maxWidth: `${size-10}px`}} />
                        </div> :

                        <SvgHexagonShape image={avatarUrl} size={size} withShadow={withShadow} blackShadow={blackShadow} />
                    :
                    <div className={classes.picBody} style={withShadow ? blackShadow ? blackShadowStyle : shadowStyle : {}}>
                        <img src={avatarUrl} alt='' width={size} />
                    </div>
            }
        </div>
    )
}