import { IWebsiteMenu, IWebsiteMenuType, WebsitePageType } from '@/shared/models/Website'
import { isEmpty } from '@/shared/utils/lodashFunc'
import { NextRouter } from 'next/router'
import { buildUrl } from 'utils/clubs'

export function isHomePage(router: NextRouter): boolean {
    const { asPath, query } = router
    // build url returns empty page sometimes which means it's homepage
    // but asPath always equals to "/" when it's home for custom domain
    const homeUrl = buildUrl(query.prefix as string) || '/'
    return asPath === homeUrl
}

export function getHomePage(menu: IWebsiteMenu[]) {
    return menu.find(menuItem => !menuItem.url && menuItem.type === IWebsiteMenuType.PAGE && menuItem.page?.type === WebsitePageType.HOME) ?? null
}

export function getActiveMenuTree(items: IWebsiteMenu[], activeMenuId: number, tree: number[]): number[] | null {
    for (let i = 0; i < items.length; i++) {
        if (items[i].id === activeMenuId) {
            return [...tree, items[i].id]
        } else {
            if (!isEmpty(items[i].children)) {
                const newTree = getActiveMenuTree(items[i].children, activeMenuId, [...tree, items[i].id])
                if (newTree) {
                    return newTree
                }
            }
        }
    }
    
    return null
}

function hasSelectedChildMenuItem(children: IWebsiteMenu[], pageSlug: string): boolean {
    return children.some(item => {
        if (item.url && item.url === pageSlug) {
            return true
        } else if (!isEmpty(item.children)) {
            return hasSelectedChildMenuItem(item.children, pageSlug)
        } else {
            return false
        }
    })
}

export function getSelectedFirstLevelMenu(router: NextRouter, pageSlug: string | null, menu: IWebsiteMenu[]): IWebsiteMenu | null {
    if (!pageSlug) {
        if (isHomePage(router)) {
            return getHomePage(menu)
        }
        return null
    }

    return menu.find(menuItem => menuItem.url === pageSlug || (!isEmpty(menuItem.children) && hasSelectedChildMenuItem(menuItem.children, pageSlug))) ?? null
}

export function getCurrentMenuItem(menu: IWebsiteMenu[], pageSlug: string): IWebsiteMenu | null {
    for (let i = 0; i < menu?.length; i++) {
        if (menu[i].url === pageSlug) {
            return menu[i]
        } else {
            if (!isEmpty(menu[i].children)) {
                const result = getCurrentMenuItem(menu[i].children, pageSlug)
                if (result) {
                    return result
                }
            }
        }
    }

    return null
}

export function getMenuUrl(item: IWebsiteMenu, pathPrefix: string, isPreview: boolean): string | null {
    
    let href = null
    const isHomePage = item?.page?.type === WebsitePageType.HOME

    if (!item.url && !isHomePage) {
      return href
    }

    if (item.type === IWebsiteMenuType.LINK && item.url) {
      return href = item.url
    }

    let basePath = ''

    // path prefix only exists for clubee.(dev|com) domains, on custom domains it is empty / not used
    // need to check the pathPrefix 'web' because of incorrect work of some links (in the menu), it happens due to the rewrites rules in next.config.js
    if (pathPrefix === '' || pathPrefix === 'web') {
      // special condition if the page is created by the preview iframe in the website builder
      basePath += isPreview ? `/preview` : ``
    } else {
      // special condition if the page is created by the preview iframe in the website builder
      basePath += isPreview ? `/${pathPrefix}/preview` : `/${pathPrefix}`
    }

    if (item.type === IWebsiteMenuType.PAGE) {
        if (isHomePage) {
            href = basePath ? `${basePath}` : '/'
        } else {
            href = (item.url.charAt(0) === '/') ? `${basePath}${item.url}` : `${basePath}/${item.url}`
        }
    }

    return href
}