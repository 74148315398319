import React from 'react'

import { useClub } from 'contexts/club'
import { WebsiteLogoPosition } from '@/shared/models/Website'
import HeaderLeftLogo from './HeaderLeftLogo'
import HeaderMiddleLogo from './HeaderMiddleLogo'
import HeaderLeftBigLogo from './HeaderLeftBigLogo'

interface IProps {
    handleMobileMenuOpen: () => void
}

export default function WebsiteHeader({ handleMobileMenuOpen }: IProps): JSX.Element {
    const { website, isFlf } = useClub()

    if(isFlf) {
        return (
            <HeaderLeftBigLogo handleMobileMenuOpen={handleMobileMenuOpen} />
        )
    }
    switch(website.logo_position) {
        case WebsiteLogoPosition.MIDDLE: {
            return <HeaderMiddleLogo handleMobileMenuOpen={handleMobileMenuOpen} />
        }
        case WebsiteLogoPosition.LEFT_BIG: {
            return <HeaderLeftBigLogo handleMobileMenuOpen={handleMobileMenuOpen} />
        }
        default: {
            return <HeaderLeftLogo handleMobileMenuOpen={handleMobileMenuOpen} />
        }
    }
}