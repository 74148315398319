import React from 'react'
import Container from '@material-ui/core/Container'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { Colors, ITheme } from '@/shared/styles/MuiTheme'
import { useClub } from 'contexts/club'
import { clubeeLogoBlack, clubeeLogoWhite } from '../consts'
import { Paragraph1, Paragraph2 } from '@/shared/components/Typography'
import { getCookieSettingsLink } from '@/shared/utils/getCookieSettingsLink'
import Icon from '@/shared/components/Icon/Icon'
import ClubLogo from '../WebsiteHeader/Elements/ClubLogo'
import ClubName from '../WebsiteHeader/Elements/ClubName'
import { IFlfThemeProps } from '@/shared/models/Club'
import { CLUBEE_BLACK } from '@/shared/constants'
import { AdManager } from '@/shared/components/AdManager/AdManager'
import { composeFooterLinks } from '@/shared/utils/helpers'
import Link from 'next/link'
import { getClubUrl } from '@/shared/utils/getClubUrl';
import { useAuth } from 'contexts/auth'

export const desktopFooterHeigth = 280
export const mobileFooterHeigth = 295

const useStyles = makeStyles((theme: ITheme) => ({
    footerLayout: {
        background: ({ isFlf }: IFlfThemeProps) => isFlf ? '#FFFFFF' : theme.props.sectionMode.background,
        position: 'relative',
        zIndex: 1,
        boxShadow: '0 0 20px 0 rgb(0, 0, 0, 25%)',
        '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: -1,
            left: '0%',
            right: '0%',
            top: '0%',
            bottom: '0%',
            background: 'linear-gradient(270deg, rgba(19, 19, 19, 0.05) 0%, rgba(19, 19, 19, 0) 100%)',
        },
        '& h3, & p': {
            color: ({ isFlf }: IFlfThemeProps) => isFlf ? `${CLUBEE_BLACK} !important` : undefined,
        },
        '& a': {
            textDecorationColor: ({ isFlf }: IFlfThemeProps) => isFlf ? `${CLUBEE_BLACK} !important` : undefined
        }
    },
    footerInner: {
        padding: theme.spacing(7, 0, 6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    branding: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    brandingLabel: {
        marginBottom: theme.spacing(3),
    },
    brandingLogo: {
        display: 'flex',
        alignItems: 'flex-end',
        paddingRight: theme.spacing(5),
        '& img': {
            height: '30px',
            width: '120'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    brandingLogoMobile: {
        display: 'none',
        '& img': {
            height: '30px',
            width: '120'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(0, 0, 4),
        }
    },
    clubLogo: {
        height: '64px',
        marginBottom: theme.spacing(2),
        '& img': {
            height: '64px',
            width: 'auto'
        }
    },
    clubName: {
        padding: theme.spacing(0, 4, 5), 
        textAlign: 'center',
        '& a h3': {
            fontSize: '16px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
            }
        }
    },
    links: {
        padding: theme.spacing(5, 0, 4),
        display: 'flex',
    },
    link: {
        padding: theme.spacing(0, 5),
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(2)
        },
        '& a': {
            textDecorationColor: theme.props.sectionMode.text_background,
            textDecoration: 'none',
        },
        '& a p': {
            fontSize: '14px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
                textAlign: 'center',
            }
        }
    },
    grouplinks: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    divider: {
        padding: theme.spacing(0, 5),
        width: '100%',
        '& hr': {
            height: '0.5px',
            mixBlendMode: 'normal',
            boxSizing: 'border-box',
            margin: 0,
            border: 0,
            width: '100%',
            backgroundColor: (props) => props.isFlf ? Colors.FlfDividerColor : 'rgb(19, 19, 19)'
        }
    },
    adRoot: {
        width: '100%',
        display: 'flex',
        margin: '0 0 25px',
        justifyContent: 'center',
    },
    footerContentWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    copyRight: {
        '& p': {
            textAlign: 'center'
        }
    },
    copyRightBranding: {
        '& p': {
            textAlign: 'left',
            paddingLeft: theme.spacing(5),
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
                paddingLeft: theme.spacing(0),
            }
        }
    },
    linksWrapper: {
        margin: '0 auto'
    },
    linksWrapperWithBranding: {
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto'
        }
    },
    footerLinksWrapper: {
        width: '80%',
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'auto',
        },
    },
    footerLinkItem: {
        marginBottom: '16px',
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            textAlign: 'center'
        },
    },
    footerLinkItemForSingleLink: {
        textAlign: 'center',
    },
    footerLink: {
        width: '240px',
        [theme.breakpoints.down('md')]: {
            width: '200px'
        },
    },
    footerLinkContent: {
        color: theme.props.sectionMode.text_background,
    }
}))

export default function Footer(): JSX.Element {
    const { website, club, isFlf, pages } = useClub()
    const classes = useStyles({ isFlf })
    const currentYear = new Date().getFullYear()
    const theme: ITheme = useTheme()
    const { user } = useAuth()
    const footerLinks = composeFooterLinks(website.footer_links, pages)
    const language = user?.language
    
    let footerBranding: boolean
    website ? footerBranding = Boolean(website.footer_clubee_branding) : footerBranding = true

    function renderAdManager(blockId): JSX.Element {
        const adClub = club?.id ?? 0

        return (
            <div key={`${blockId}-ad`} className={classes.adRoot}>
                {adClub && (
                    <AdManager
                        adType='footer'
                        blockId={blockId}
                        sizes={[[300, 50], [234, 60]]}
                        color={theme.props.sectionMode.background}
                    />
                )}
            </div>
        )
    }

    if (!club) {
        return null
    }

    return (
        <footer className={classes.footerLayout}>
            <Container maxWidth="lg" className={classes.footerInner}>
                {renderAdManager(club.id)}
                <div className={classes.branding}>
                    {Boolean(club?.logo_name) && (
                        <div className={classes.clubLogo}>
                            <ClubLogo />
                        </div>
                    )}
                    {Boolean(club) && (
                        <div className={classes.clubName}>
                            <ClubName isAccent={false} />
                        </div>
                    )}
                </div>
                <div className={classes.footerLinksWrapper}>
                    {footerLinks.map((i, index) => {
                            if (i.isPage) {
                                const clubUrl = getClubUrl(club)
                                return (
                                    <div key={i.link + '-' + index} className={`${classes.footerLinkItem} ${footerLinks.length === 1 ? classes.footerLinkItemForSingleLink : ''}`}>
                                        <div className={classes.footerLink}>
                                            <Link href={`${clubUrl}/${i.link}`}>
                                                <a className={classes.footerLinkContent}>
                                                    {i.name}
                                                </a>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={i.link + '-' + index} className={`${classes.footerLinkItem} ${footerLinks.length === 1 ? classes.footerLinkItemForSingleLink : ''}`}>
                                        <div className={classes.footerLink}>
                                            <Link href={i.link}>
                                                <a className={classes.footerLinkContent}>
                                                    {i.name}
                                                </a>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                <div className={classes.divider}>
                    <hr/>
                </div>
                <div className={classes.footerContentWrapper}>
                    <div className={footerBranding ? classes.linksWrapperWithBranding : classes.linksWrapper}>
                        <div className={classes.links}>
                            <div className={classes.grouplinks}>
                                <div className={classes.link}>
                                    <a href={`https://get.clubee.com/${user ? 'data-processing-agreement' : 'privacy-policy'}${language ? `?hsLang=${language}` : ''}`} target="_blank" rel="noreferrer">
                                        <Paragraph1><FormattedMessage id='v4_website.footer.privacy.policy' defaultMessage='Privacy policy' /></Paragraph1>
                                    </a>
                                </div>
                                <div className={classes.link}>
                                    <a href={getCookieSettingsLink(website)} target="_blank" rel="noreferrer">
                                        <Paragraph1><FormattedMessage id='v4_website.footer.cookie.settings' defaultMessage='Cookie settings' /></Paragraph1>
                                    </a>
                                </div>
                            </div>
                            <div className={classes.grouplinks}>
                                <div className={classes.link}>
                                    <a href={`https://get.clubee.com/terms-conditions${user ? '' : '-users'}${language ? `?hsLang=${language}` : ''}`} target="_blank" rel="noreferrer">

                                        <Paragraph1><FormattedMessage id='v4_website.footer.terms.of.use' defaultMessage='Terms of use' /></Paragraph1>
                                    </a>
                                </div>
                                <div className={classes.link}>
                                    <a href="https://www.clubee.com/legal-disclaimer" target="_blank" rel="noreferrer">
                                        <Paragraph1><FormattedMessage id='v4_website.footer.legal.disclaimer' defaultMessage='Legal disclaimer' /></Paragraph1>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {footerBranding && (
                            <div className={classes.brandingLogoMobile}>
                                <Icon src={Boolean(website) ? clubeeLogoWhite : clubeeLogoBlack} hexColor={isFlf ? CLUBEE_BLACK : theme.props.sectionMode.text_background} />  {/* Use Black logo if website does not exists */}
                            </div>
                        )}
                        <div className={footerBranding ? classes.copyRightBranding : classes.copyRight}>
                            <Paragraph2>
                                <FormattedMessage id="v4_website.footer.copyright_1" defaultMessage="Copyright  © 2010 -" />
                                {` ${currentYear} `}
                                <FormattedMessage id="v4_website.footer.copyright_2" defaultMessage="Clubee. All Rights Reserved." />
                            </Paragraph2>
                        </div>
                    </div>
                    {footerBranding && (
                        <div className={classes.brandingLogo}>
                            <Icon src={Boolean(website) ? clubeeLogoWhite : clubeeLogoBlack} hexColor={isFlf ? CLUBEE_BLACK : theme.props.sectionMode.text_background} />  {/* Use Black logo if website does not exists */}
                        </div>
                    )}
                </div>
            </Container>
        </footer>
    )
}

