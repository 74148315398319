import { Colors } from '@/shared/styles/MuiTheme'
import { makeStyles } from '@material-ui/core/styles'
import { ITheme } from '@/shared/styles/MuiTheme'

export const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'none',
        borderRadius: '25px',
        color: 'white',
        height: '32px',
        lineHeight: '32px',
        fontSize: '16px',
        fontWeight: 400,
        border: '0',
        textDecoration: 'none',
        textTransform: 'none',
        padding: '0 18px',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        '&:hover': {
            color: 'white',
            textDecoration: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        '& a, & a:hover, & a:focus': {
            color: 'inherit',
            textDecoration: 'initial',
        },
        '&--z-index-1': {
            zIndex: 1,
        },
        '&--capitalize': {
            textTransform: 'capitalize',
        },
        '&--cta': {
            height: '50px',
            lineHeight: '50px',
            padding: '0 35px',
    
            '& $loader .MuiCircularProgress-root': {
                width: '30px !important',
                height: '30px !important',
            },
        },
        '&--icon': {
            width: '32px',
            height: '32px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: '0',
            margin: '0',
            borderRadius: '100%',
            minWidth: '32px',
            minHeight: '32px',
            maxWidth: '32px',
            maxHeight: '32px',
    
            '&-size-21': {
                width: '21px',
                height: '21px',
                minWidth: '21px',
                minHeight: '21px',
                maxWidth: '21px',
                maxHeight: '21px',
            },
    
            '&-size-50': {
                width: '50px',
                height: '50px',
                minWidth: '50px',
                minHeight: '50px',
                maxWidth: '50px',
                maxHeight: '50px',
                fontSize: '25px',
            },
    
            '&-size-55': {
                width: '55px',
                height: '55px',
                minWidth: '55px',
                minHeight: '55px',
                maxWidth: '55px',
                maxHeight: '55px',
                fontSize: '25px',
    
                '& img': {
                    height: '21px',
                },
            },
    
            '& i, & img': {
                display: 'block',
            },
    
            '& $loader': {
                margin: '0',
            }
        },
        '&--facebook': {
            background: Colors.Facebook,
            '&:hover, &:focus': {
                backgroundColor: Colors.Facebook,
                '&$root': { '&--cta': {
                    backgroundColor: Colors.Facebook,
                }},
            },
            '&$root': { '&--active, &:active': {
                backgroundColor: Colors.Facebook,
                '&$root': { '&--cta': {
                    backgroundColor: Colors.Facebook,
                }},
            }},
        },
        '&--twitter': {
            background: Colors.Twitter,
            '&:hover, &:focus': {
                backgroundColor: Colors.Twitter,
                '&$root': { '&--cta': {
                    backgroundColor: Colors.Twitter,
                }},
            },
            '&$root': { '&--active, &:active': {
                backgroundColor: Colors.Twitter,
                '&$root': { '&--cta': {
                    backgroundColor: Colors.Twitter,
                }},
            }},
        },
        '&--green, &--primary': {
            background: Colors.Primary,
            '&:hover, &:focus': {
                backgroundColor: Colors.GreenHover,
                '&$root': { '&--cta': {
                    backgroundColor: Colors.GreenHoverCTA,
                }},
            },
            '&$root': { '&--active, &:active': {
                backgroundColor: Colors.GreenActive,
                '&$root': { '&--cta': {
                    backgroundColor: Colors.GreenActiveCTA,
                }},
            }},
        },
        '&--grey, &--default': {
            background: Colors.MainGrey,
            '&:hover, &:focus': {
                backgroundColor: Colors.GreyHover,
                '&$root': { '&--icon': {
                    backgroundColor: Colors.GreyHoverIcon,
                }},
            },
            '&$root': { '&--active, &:active': {
                backgroundColor: Colors.GreyActive,
                '&$root': { '&--icon': {
                    backgroundColor: Colors.GreyActiveIcon,
                }},
            }},
        },
        '&--blue, &--secondary': {
            background: Colors.Secondary,
            '&:hover, &:focus': {
                backgroundColor: Colors.BlueHover,
                '&$root': {
                    '&--icon': {
                        backgroundColor: Colors.BlueHoverIcon,
                    },
                    '&--cta': {
                        backgroundColor: Colors.BlueHoverCTA,
                    }
                },
            },
            '&$root': { '&--active, &:active': {
                backgroundColor: Colors.BlueActive,
                '&$root': {
                    '&--icon': {
                        backgroundColor: Colors.BlueActiveIcon,
                    },
                    '&--cta': {
                        backgroundColor: Colors.BlueHoverCTA,
                    }
                },
            }},
        },
        '&--red, &--danger': {
            background: Colors.MainRed,
            '&:hover, &:focus': {
                backgroundColor: Colors.RedHover,
                '&$root': {
                    '&--icon': {
                        backgroundColor: Colors.RedHoverIcon,
                    }
                }
            },
            '&$root': {
                '&--icon': {
                    background: Colors.MainRedIcon,
                },
                '&--active, &:active': {
                    backgroundColor: Colors.RedActive,
                    '&$root': {
                        '&--icon': {
                            backgroundColor: Colors.RedActiveIcon,
                        }
                    }
                }
            },
        },
        '&--yellow, &--special': {
            background: Colors.MainYellow,
            '&:hover, &:focus': {
                backgroundColor: Colors.YellowHover,
                '&$root': {
                    '&--icon': {
                        backgroundColor: Colors.YellowHoverIcon,
                    }
                }
            },
            '&$root': {
                '&--icon': {
                    background: Colors.MainYellowIcon,
                },
                '&--active, &:active': {
                    backgroundColor: Colors.YellowActive,
                    '&$root': {
                        '&--icon': {
                            backgroundColor: Colors.YellowActiveIcon,
                        }
                    }
                }
            },
        },
        '&:disabled, &.disabled, &--disabled': {
            cursor: 'default',
            pointerEvents: 'none',
            border: '0',
            background: Colors.MainDisabled,
            color: 'white',
            '& .MuiCircularProgress-root': {
                color: 'inherit',
            },
            '&:hover, &:focus': {
                backgroundColor: Colors.DisabledHover,
                '&$root': {
                    '&--icon': {
                        backgroundColor: Colors.DisabledHoverIcon,
                    }
                }
            },
            '&$root': { '&--active, &:active': {
                backgroundColor: Colors.DisabledActive,
                '&$root': {
                    '&--icon': {
                        backgroundColor: Colors.DisabledActiveIcon,
                    }
                }
            }}
        },
        '&--loading': {
            cursor: 'default',
            pointerEvents: 'none',
            border: '0',
            background: Colors.MainGrey,
            color: 'white',
            '& .MuiCircularProgress-root': {
                color: 'inherit',
            },
            '&:hover, &:focus': {
                color: 'white',
                backgroundColor: Colors.MainGrey,
                '&$root': {
                    '&--icon': {
                        color: 'white',
                        backgroundColor: Colors.MainGrey,
                    }
                }
            },
            '&$root': {
                '&--active, &:active': {
                    color: 'white',
                    backgroundColor: Colors.MainGrey,
                    '&$root': {
                        '&--icon': {
                            color: 'white',
                            backgroundColor: Colors.MainGrey,
                        }
                    }
                }
            }
        },
        '&--text, &--link': {
            fontWeight: 'bold',
            textDecoration: 'underline',
            color: Colors.MainGrey,
            border: '0',
            background: 'none',
            '&:hover, &:focus': {
                color: Colors.OutlineHoverCTA,
                textDecoration: 'underline',
            },
            '&$root': {
                '&--active, &:active': {
                    color: Colors.OutlineActiveCTA,
                    textDecoration: 'underline',
                },
                '&--loading, &--disabled, &:disabled': {
                    background: 'none!important',
                    color: `${Colors.MainDisabled}!important`,
                },
                '&--red-color': {
                    color: Colors.MainRed,
                }
            },
        },
        '&--outline': {
            background: 'white',
            border: `1px solid ${Colors.MainGrey}`,
            color: Colors.MainGrey,
            '&:hover, &:focus': {
                borderColor: '#ACC1E3',
                color: Colors.GreyHover,
                '&$root': { '&--cta': {
                    borderColor: Colors.OutlineHoverCTA,
                    color: Colors.OutlineHoverCTA,
                }},
            },
            '&$root': {
                '&--active, &:active': {
                    backgroundColor: '#E6EDF8',
                    borderColor: Colors.GreyActive,
                    color: Colors.GreyActive,
                    '&$root': { '&--cta': {
                        backgroundColor: '#E7EDF7',
                        borderColor: Colors.OutlineActiveCTA,
                        color: Colors.OutlineActiveCTA,
                    }}
                }
            }
        },
        '&--outline-white': {	
            background: 'none',	
            border: '1px solid white',	
            color: 'white',	
            '&:hover, &:focus': {	
                borderColor: 'rgb(255, 255, 255, 0.9)',	
                color: 'rgb(255, 255, 255, 0.9)',	
            },
            '&$root': {
                '&--active, &:active': {	
                    backgroundColor: 'rgb(255, 255, 255, 0.1)',	
                    borderColor: Colors.GreyActive,	
                    color: Colors.GreyActive,	
                }
            }	
        },
        '&--border-white': {
            border: '1px solid white',
        },    
        '&--no-padding': {
            padding: '0',
        },    
        '&--no-underline': {
            textDecoration: 'none',
            '&$root': {
                '&:hover, &--active, &:active, &:focus': {
                    textDecoration: 'none',
                }
            }
        },
        '&--blue-text': {
            color: `${Colors.Secondary} !important`,
        },    
        '&--white-text': {	
            color: 'white !important',
        },    
        '&--red-text': {
            color: `${Colors.MainRed} !important`,
            '&:hover': {
                color: `${Colors.MainRed} !important`,
            }            
        },
        '&--right-split-border': {
            borderRight: `1px solid ${Colors.MainGrey} !important`,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
        '&--full-width': {
            width: '100%'
        },    
        '&--absolute-center': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }
    },
    loader: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '5px',
    },
    liveStreamButton: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        backgroundColor: 'transparent',
        color: 'inherit',
        fontFamily: 'inherit',
        border: `1px solid ${theme.props.sectionMode.text_background}`,
        borderRadius: 4,
        padding: '2px 4px',
        cursor: 'pointer',
        width: 'max-content'
    },
    liveStreamButtonText: {
        fontSize: 12,
        lineHeight: '19px'
    },
    liveStreamButtonCircle: {
        height: 8,
        width: 8,
        borderRadius: 4,
        backgroundColor: Colors.FlfRedColor,
        marginRight: 4
    },
    replayImg: {
        marginRight: 5
    },
    playButton: {
        marginRight: 5,
        border: 0,
        background: 'transparent',
        boxSizing: 'border-box',
        width: 0,
        borderColor: `transparent transparent transparent ${theme.props.sectionMode.text_background}`,
        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: '5px 0 5px 9px'
    }
}))