import { createContext, useContext } from 'react'

export const ContainerHeightContext = createContext<number>(0);

export function useContainerHeight() {
  return useContext(ContainerHeightContext)
}

export default ContainerHeightContext



