import { IClub } from './Club'
import { ICountry } from './Country'
import { IGroup, IGroupSeasonIds } from './Group'
import { ISeason } from './Season'
import { IAssignedTag } from './Tag'
import { IConnectedAccount } from './User'

export const EVENT_TYPE_EVENT = 'event'
export const EVENT_TYPE_TRAINING = 'training'
export const EVENT_TYPE_GAME = 'game'

export interface IEvent {
    type: string; //game|training|event
    seo_id?: string; // training|event
    id: number;
    title: string;
    start_date: string;
    end_date?: string;
    info?: string;
    scene_id?: number; //game
    venue_name?: string;
    venue_address_name?: string;
    venue_address?: string;
    venue_city?: string;
    venue_zip?: string;
    venue_country?: ICountry;
    form?: {
        id: number;
        club: { id: number };
        button_text?: string;
    };
    club: IClub;
    event_teams?: {
        group: IGroup;
        season?: ISeason;
    }[];
    attendance?: string;
    picture: string;
    venue_lat: number;
    venue_lng: number;
    send_automatic_invitation: boolean;
    send_invitation_now: boolean;
    recurrent: boolean;
    visibility: 'groups' | 'everybody';
    event_access: Array<{ group: { id: number }; season?: { id: number } }>;
    files: Array<{ file: string; id: number; name: string }>;
    shared_with: Array<{ club: { id: number } }>;
    groups_invited: Array<{ group: { id: number }; season?: { id: number } }>;
    information: string;
    frequency: 'week' | 'two_week' | 'month';
    stream_link?: string;
    facility?: {
        id: number;
        lat: string;
        lng: string;
        name: string;
        venue_address: string;
        venue_city: string;
        venue_zip: string;
        venue_country: {
            id: number;
            name: string;
        };
    };
    tags?: IAssignedTag[];
}

export interface IEventNews {
    type: string;
    id: number;
    date: number;
    description: string;
    title: string;
    image: string;
    content: string;
    file: string;
    headline: string;
    seo_id: string;
    thumb: string;
}

export interface IEventGallery {
    type: string;
    id: number;
    date: number;
    description: string;
    title: string;
    image: number;
    seo_id: number;
}

export interface IEventVideo {
    type: string;
    id: number;
    date: number;
    description: string;
    title: string;
    image: string;
    seo_id: string;
}

export interface IUserEventItem {
    type: string; //game|training|event
    seo_id?: string; // training|event
    id: number;
    title: string;
    start_date: string;
    end_date?: string;
    info?: string;
    scene_id?: number; //game
    venue_name?: string;
    venue_address_name?: string;
    venue_address?: string;
    venue_city?: string;
    venue_zip?: string;
    venue_country?: ICountry;
    form?: {
        id: number;
        club: { id: number };
        button_text?: string;
    };
    club: IClub;
    event_teams?: {
        group: IGroup;
        season?: ISeason;
    }[];
    attendance: {
        type: string;
        comments?: {
            user: number;
            comment: string;
            datetime: string;
        }[];
    };
    roles?: { role: number}[];
    restrictions?: {
        role: number;
        allow: boolean;
    }[];
}

export interface IAttendanceRole {
    name: string,
    spots_limit_enabled?: boolean
    spots_limit_number?: number
    allow_choose_role?: boolean
    groups?: IGroupSeasonIds[]
    save_as_template?: boolean
    membersIds: number[]
    is_new?: boolean
    id?: number | string
}

export interface IEventAttendance {
    attendance: {
        type: string;
        comments?: {
            user?: any;
            comment?: string;
            datetime?: string;
        }[];
        user?: IConnectedAccount;
    };
    roles?: { role: number}[];
    restrictions?: {
        role: number;
        allow: boolean;
    }[];
}

export interface IAttendanceItem {
    type: string;
    user;
    comments?: {
        user?: any;
        comment?: string;
        datetime?: string;
    }[];
}