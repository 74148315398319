import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useRouter } from 'next/router'

import { useAuth } from 'contexts/auth'
import MButton from '@/shared/components/Button/MButton'
import { useClub } from 'contexts/club'
import { getOriginFromUrl } from 'utils/clubs'
import PrimaryButton from '@/shared/components/Button/PrimaryButton'

export default function LoginButton(): JSX.Element {
    const { token } = useAuth()
    const { prefix, isFlf } = useClub()
    const router = useRouter()

    if(isFlf) {
        return (
            <PrimaryButton size='small' isClubExternal={false} href={process.env.NEXT_PUBLIC_FLF_EXTRANET_LINK}>
                <FormattedMessage id='v4_website.extranet' defaultMessage='Log in' />
            </PrimaryButton>
        )
    }

    if (token) {
        return null
    }

    const origin = getOriginFromUrl(prefix, router.asPath)

    return <MButton
        variant='outlined'
        color='primary'
        size='small'
        isClubExternal
        href={`/user/login?origin=${origin}`}
    >
        <FormattedMessage id='v4_website.btn.login' defaultMessage='Log in' />
    </MButton>
}