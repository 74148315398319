import React from 'react'

import SvgHexagonShape from './SvgHexagonShape'

import { avatarStyles } from './styles'
import { stringToHexCode } from '@/shared/utils/stringToHexCode'
import { getFirstLastInitials } from '@/shared/utils/getInitials'
import applyMods from '@/shared/utils/applyMods'

interface IInitialsAvatarProps {
    name: string;
    size?: number;
    isHexagon?: boolean;
    withShadow?: boolean;
}

export function InitialsAvatar({
    name,
    size = 35,
    isHexagon = false,
    withShadow = false,
}: IInitialsAvatarProps): JSX.Element {
    const classes = avatarStyles()
    const color = stringToHexCode(name)
    const firstAndLastInitials = getFirstLastInitials(name)
    
    return (
        <div className={classes.root}>
            {
                isHexagon ?
                    <div className={applyMods(classes.initialsBody, 'hexagone')}>
                        <SvgHexagonShape color={color} size={size} withShadow={withShadow} />
                        <div className={classes.initialsText}>
                            {firstAndLastInitials}
                        </div>
                    </div>
                    :
                    <div
                        className={applyMods(classes.initialsBody, `round|size-${size}`)}
                        style={{
                            'backgroundColor': color
                        }}>
                            <div className={classes.initialsText}>
                                {firstAndLastInitials}
                            </div>
                    </div>
            }
        </div>
    )
}