import Link from 'next/link';
import { ReactNode } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

type IElementLinkProps = {
    link: string,
    openLinkNewTab?: boolean,
    children: ReactNode,
    target?: string
}

const useElementLinkStyles = makeStyles({
    link: {
        textDecoration: 'none'
    }
})

export default function ElementLink(props: IElementLinkProps) {
    const { link, openLinkNewTab, children, target } = props
    const classes = useElementLinkStyles()
    return (
        <Link href={link || '#'} passHref scroll={false}>
            <a className={classes.link} target={target || ''} {...openLinkNewTab ? {
                target: '_blank',
                rel: 'noreferrer',
            } : {}} >
                {children}
            </a>
        </Link>
    )
}