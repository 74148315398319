import Link from 'next/link';
import { ReactNode } from 'react';

interface IMenuLinkProps {
    href: string
    native?: boolean,
    openInNewTab: boolean
    children?: ReactNode
}

export default function MenuLink(props: IMenuLinkProps) {
    const { native, href, openInNewTab, ...restProps } = props
    const anchorProps = {
        ...restProps,
        ...openInNewTab ? {target: '_blank', rel: 'noreferrer'} : {}
    }

    if(native) {
        return (
            <a onClick={e => e.stopPropagation()} href={href} {...anchorProps}/>
        )
    }

    return (
        <Link
            href={href}
            passHref>
             <a onClick={e => e.stopPropagation()} {...anchorProps}/>
        </Link>
    )
}