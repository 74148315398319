import React from 'react'

import AdminMenuItem from './AdminMenuItem'
import { menuStyles } from './styles'

function Column(props): JSX.Element {
    const menuClasses = menuStyles()

    return <div className={menuClasses.menuColumn}>
        {props.categories.map((item) => <AdminMenuItem key={item.id} {...item} />)}
    </div>
}

export default function AdminMenuColumns({ menu }): JSX.Element {
    const menuClasses = menuStyles()

    return <div className={menuClasses.menuRow}>
        {menu.columns.map(col => <Column key={col.id} {...col} />)}
    </div>
}