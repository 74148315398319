import { IClub } from '@/shared/models/Club'
import applyMods from '@/shared/utils/applyMods'
import { isUndefined } from '@/shared/utils/lodashFunc'
import { useClub } from 'contexts/club'
import { useStyles } from '../Button/styles'

interface IExternalLinkProps {
    href: string;
    children: JSX.Element;
    isClubUrl?: boolean;
    isButton?: boolean;
    mods?: string;
    className?: string;
    externalClub?: IClub;
}

export default function ExternalLink({
    isClubUrl,
    isButton,
    href,
    children,
    mods,
    className,
    externalClub,
    ...rest
}: IExternalLinkProps & any): JSX.Element {
    let externalHref = href
    let clubForUrl = externalClub
    const btnClasses = useStyles()

    if (isClubUrl) {
        if (isUndefined(clubForUrl)) {
            const { club } = useClub()
            clubForUrl = club
        }
        
        externalHref = `${
            clubForUrl.domain_online ?
                `https://www.${clubForUrl.domain}`
                : `${process.env.NEXT_PUBLIC_APP_WEBSITE}/${clubForUrl.suffix}`
        }${href}`
    }

    return (
        <a href={externalHref} {...rest} className={`${className || ''} ${isButton ? applyMods(btnClasses.root, mods || '') : ''}`}>
            {children}
        </a>
    )
}