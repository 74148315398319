import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '@/shared/styles/MuiTheme';

export const menuStyles = makeStyles({
    container: {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '0 15px'
    },
    menu: {
        marginRight: 'auto'
    },
    menuContainer: {
        position: 'relative',
        top: 0,
        left: 0,
        padding: 0,
        zIndex: 1000,
    },
    menuToggle: {
        cursor: 'pointer',
        padding: 0,
        width: '80px',
        height: '64px',
        display: 'flex',
        alignItems: 'center',
        '& img': {
            verticalAlign: 'middle',
            borderStyle: 'none',
        }
    },
    menuContent: {
        color: 'white',
        backgroundColor: '#3682ff',
        background: 'linear-gradient(180deg, #3682ff 0%, #245fbf)',
        top: '64px',
        padding: '20px 0',
        position: 'fixed',
        left: 0,
        width: '100vw',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 64px)',
        letterSpacing: 0
    },
    menuCloseBtn: {
        fontSize: '50px',
        verticalAlign: 'middle',
        cursor: 'pointer',
        padding: '8px 16px',
        borderRadius: '25px',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    menuRow: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: '-15px',
        marginLeft: '-15px',
    },
    menuColumn: {
        borderRight: '1px solid rgba(255, 255, 255, 0.3)',
        flexBasis: 0,
        flexGrow: 1,
        maxWidth: '100%',
        position: 'relative',
        width:' 100%',
        paddingRight: '15px',
        paddingLeft: '15px',
        '&:last-child': {
            borderRight: 0,
        }
    },
    menuItem: {
        borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
        marginBottom: '20px',
        paddingBottom: '10px',
        lineHeight: '1.5'
    },
    menuItemInner: {
        padding: '0 10px',
    },
    menuGroupTitle: {
        display: 'flex',
        fontSize: '16px',
        textTransform: 'uppercase',
        fontWeight: 700,
        margin: '0 0 5px 0',
    },
    menuIconItem: {
        marginRight: '7px'
    },
    menuIconItemBlack: {
        marginRight: '7px',
        display: 'none'
    },
    menuIconItemLock: {
        marginRight: '7px'
    },
    menuIcon: {
        color: 'white',
        fontSize: '30px',
        verticalAlign: 'middle',
        '&--left': {
            marginRight: '7px',
        }
    },
    menuSubitems: {
        paddingLeft: 0,
        listStyle: 'none',
    },
    menuSubitem: {
        display: 'flex',
        margin: '16px 0',
        paddingLeft: '15px',
        cursor: 'pointer',

        '&:hover': {
            '& $menuLink': {
                color: '#0b1f40'
            },
            '& $menuIconItemBlack': {
                display: 'block'
            },
            '& $menuIconItem': {
                display: 'none'
            }
        }
    },
    menuSubicon: {
        color: 'white',
        fontSize: '23px',
        verticalAlign: 'middle',
        marginRight: '7px',
        marginLeft: '5px'
    },
    menuLink: {
        color: 'white',
        fontSize: '14px',
        cursor: 'pointer',
        textDecoration: 'none',
        fontWeight: 400,
        textTransform: 'uppercase',
    },
    menuLinkDisabled: {
        display: 'flex',
        fontSize: '14px',
        cursor: 'text',
        textDecoration: 'none',
        fontWeight: 400,
        textTransform: 'uppercase',
        color: Colors.MainDisabled,
    }
});