import React from 'react';
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles } from '@material-ui/core/styles';

import MButton from '@/shared/components/Button/MButton';
import { ITheme } from '@/shared/styles/MuiTheme';
import { useClub } from 'contexts/club';
import { CLUBEE_BLACK } from '@/shared/constants';

interface IProps {
    onClick: () => void;
}

interface IStylesProps {
    isFlf: boolean
}

export const buttonStyles = makeStyles((theme: ITheme) => ({
    icon: {
        color: (styleProps: IStylesProps) => styleProps.isFlf ? CLUBEE_BLACK: theme.props.sectionMode.text_accent,
        display: 'flex'
    }
}))

export default function MobileMenuButton({ onClick }: IProps): JSX.Element {
    const { isFlf } = useClub()
    const classes = buttonStyles({ isFlf })

    return <MButton onClick={onClick} iconSize={48} isIcon aria-label="menu">
        <div className={classes.icon}>
            <MenuIcon />
        </div>
    </MButton>
}