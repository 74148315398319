import React from 'react'

import MButton, { IButtonProps } from './MButton'
import { Paragraph1 } from '../Typography'


export default function TextButton({
    children,
    ...rest
}: IButtonProps): JSX.Element {
    return (
        <MButton
            isText
            disableRipple
            {...rest}
        >
            <Paragraph1 isMobile>{children}</Paragraph1>
        </MButton>
    )
}