import React, { useState } from 'react'

import ExternalLink from '@/shared/components/ExternalLink/ExternalLink'
import applyMods from '@/shared/utils/applyMods'
import { useAuth } from 'contexts/auth'
import { Avatar } from '@/shared/components/Avatar/Avatar'
import { barStyles } from './styles'
import AdminMenu from './AdminMenu/AdminMenu'
import { useTranslations } from 'contexts/translations'
import { generateMobileUrl } from '@/shared/utils/generateMobileUrl'

export default function UserTopNavBar(): JSX.Element {
    const topBarClasses = barStyles()
    const [ menuIsOpen, setMenuIsOpen ] = useState(false)
    const { translations } = useTranslations()
    const authContext = useAuth()
    const { user, token} = authContext

    if (!token || !user) {
        return null
    }

    const dashboardUrl = generateMobileUrl(token, translations.locale)

    return (
        <div className={topBarClasses.header}>
            <div className={applyMods(topBarClasses.headerBackground, menuIsOpen ? 'open' : '')}>
                <div className={topBarClasses.container}>
                    <div className={topBarClasses.inner}>
                        <ExternalLink
                            className={topBarClasses.logo}
                            href={dashboardUrl}
                        >
                            <img src={`${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}/logo_colors_outline.svg`} height='40' alt='Clubee' />
                        </ExternalLink>
                        <AdminMenu isMenuOpen={setMenuIsOpen} />
                        <div className={topBarClasses.avatar}>
                            <ExternalLink href={dashboardUrl + `&c=profile&profile=dashboard`}>
                                <Avatar size={35} url={user.pic_avatar} type='user' isHexagon />
                            </ExternalLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}