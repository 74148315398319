import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import applyMods from '@/shared/utils/applyMods'
import { ITheme } from '@/shared/styles/MuiTheme';

interface IStylesProps {
    size: number;
}

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&--center': {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            background: 'rgba(255, 255, 255, 0.3)',
        },
        '&--height-100px': {
            height: '100px',
        },    
        '&--on-top-of-form': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            background: 'rgba(255, 255, 255, 0.3)',
        },
    },
    bottom: {
        color: theme.props.sectionMode.card,
        width: ({ size }: IStylesProps) => `${size}px`,
        height: ({ size }: IStylesProps) => `${size}px`,
    },
    top: {
        position: 'absolute',
        // left: 0,
        // animationDuration: '550ms',
        color: theme.props.sectionMode.accent,
        width: ({ size }: IStylesProps) => `${size}px`,
        height: ({ size }: IStylesProps) => `${size}px`,     
    },
    circle: {
        strokeLinecap: 'round',
    },
}))

export default function Loader({ mods, size = 48 }: { mods?: string; size?: number }): JSX.Element {
    const classes = useStyles({ size });

    return (
        <div className={applyMods(classes.root, mods)}>
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={size}
                thickness={4}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                // disableShrink
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                size={size}
                thickness={4}
            />
        </div>
    )
}