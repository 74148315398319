import React from 'react'

import MButton, { IButtonProps } from './MButton'

export default function PrimaryButton({
    children,
    ...rest
}: IButtonProps): JSX.Element {
    return (
        <MButton
            variant='contained'
            color='primary'
            {...rest}
        >
            {children}
        </MButton>
    )
}