import React from 'react'
import MuiButton from '@material-ui/core/Button';
import { ButtonProps } from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { ITheme } from '@/shared/styles/MuiTheme';
import { Headline3, Headline4 } from '../Typography';
import Icon from '../Icon/Icon';
import { useClub } from 'contexts/club';
import { getClubUrl } from '@/shared/utils/getClubUrl';
import { IClub } from '@/shared/models/Club';

interface IStylesProps {
    iconSize: number;
}

export const buttonStyles = makeStyles((theme: ITheme) => ({
    button: {
        '& img, & svg, & i': {
            fontSize: '16px',
            height: '16px',
            width: '16px',
        }
    },
    buttonWithBackground: {
        backgroundColor: theme.props.sectionMode.background + '80',
        '&:hover': {
            backgroundColor: theme.props.sectionMode.background + '40',
        }
    },
    round: {
        padding: theme.spacing(0),
        minHeight: 'auto',
        minWidth: 'auto',
        borderRadius: '50px',
        width: ({ iconSize }: IStylesProps) => `${iconSize}px`,
        height: ({ iconSize }: IStylesProps) => `${iconSize}px`,
        '& img, & svg, & i': {
            width: ({ iconSize }: IStylesProps) => `${iconSize/2}px`,
            height: ({ iconSize }: IStylesProps) => `${iconSize/2}px`
        }
    },
}))

export interface IButtonProps extends ButtonProps {
    isIcon?: boolean;
    isText?: boolean;
    isAccent?: boolean;
    imgSrc?: string;
    startImg?: boolean;
    endImg?: boolean;
    iconSize?: number;
    isClubExternal?: boolean;
    externalClub?: IClub;
    applyBackgroundColor?: boolean;
}


export default function MButton({
    children,
    type = 'button',
    size = 'large',
    iconSize = 32,
    color = 'primary',
    isAccent,
    startImg,
    endImg,
    imgSrc,
    isIcon,
    isText,
    isClubExternal,
    externalClub,
    href,
    onClick,
    applyBackgroundColor = false,
    ...rest
}: IButtonProps ): React.ReactElement {
    const isPrimary = color === 'primary'
    const classes = buttonStyles({ iconSize })
    const theme: ITheme = useTheme()
    const { club } = useClub()
    let link = href ?? null

    if (href && isClubExternal && (externalClub || club)) {
        link = `${getClubUrl(externalClub || club)}${href}`
    }

    function renderIcon() {
        if (imgSrc) {
            const iconHexColor = isText ?
                theme.props.sectionMode.text_background :
                    isAccent || isPrimary ? theme.props.sectionMode.text_accent : theme.props.sectionMode.text_background
            return <Icon src={imgSrc} hexColor={iconHexColor} />
        }
        return null
    }

    function renderBtnContent() {
        if (isIcon) {
            if (imgSrc) {
                return renderIcon()
            } else {
                return children
            }
        } else if (isText) {
            return children
        } else if (size === 'large') {
            return <Headline3 isMobile isAccent={isAccent || isPrimary}>{children}</Headline3>
        } else {
            return <Headline4 isMobile isAccent={isAccent || isPrimary}>{children}</Headline4>
        }
    }

    return (
        <MuiButton
            className={`${classes.button}${applyBackgroundColor ? ` ${classes.buttonWithBackground}` : ''}${isIcon ? ` ${classes.round}` : ''}`}
            disableElevation
            type={type}
            size={size}
            color={color}
            startIcon={startImg ? renderIcon() : ''}
            endIcon={endImg ? renderIcon() : ''}
            onClick={onClick ? onClick : null}
            href={link || ''}
            {...rest}
        >
            {renderBtnContent()}
        </MuiButton>
    )
}