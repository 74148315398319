/* eslint-disable @next/next/no-img-element */
import ElementLink from '@/shared/components/ElementLink/ElementLink'
import ResponsiveClubLogo, { IResponsiveClubLogoProps } from '@/shared/components/Img/ResponsiveClubLogo'
import { getClubUrl } from '@/shared/utils/getClubUrl'
import { useClub } from 'contexts/club'
import { useMemo } from 'react'


export default function ClubLogo(props: IResponsiveClubLogoProps): JSX.Element {
    const { club } = useClub()

    const link = useMemo(() => club ? getClubUrl(club) : '', [club])

    return (
        <ElementLink link={link}>
            <ResponsiveClubLogo {...props}/>
        </ElementLink>
    )
}